// @flow

import * as React from "react";

import { DefaultErrorPage } from "tabler-react";
import type { Props as DefaultErrorProps } from "tabler-react";

function Error400Page({
  title = "400",
  subtitle = "Uups... Du fandt en fejlside...",
  details = "Beklager, men din anmodning indeholder dårlig syntaks og kan ikke opfyldes...",
  action,
}: DefaultErrorProps): React.Node {
  return (
    <DefaultErrorPage
      title={title}
      subtitle={subtitle}
      details={details}
      action={action}
    />
  );
}

export default Error400Page;