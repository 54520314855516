import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Page, Button, Text } from "tabler-react";

//-------TEST-------TEST-------TEST-------TEST-------TEST-------TEST-------TEST-------TEST
import Timeline from "react-calendar-timeline";
// make sure you include the timeline stylesheet or the timeline will not be styled
import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";

//-------TEST-------TEST-------TEST-------TEST-------TEST-------TEST-------TEST-------TEST

import auth from "../../utils/auth";

import DescriptionsStrat from "../../data/DescriptionsStrat";

import { useHistory } from "react-router-dom";

import SiteWrapper from "../SiteWrapper.js";

// const initiativeText = DescriptionsStrat.initiatives.text;

function InitiativesShow() {
  let history = useHistory();
  const [initiatives, setInitiatives] = useState([]);

  const [activities, setActivities] = useState([]);

  useEffect(() => {
    axios
      .get(`https://api.beecon.dk/activities`, {
        params: { businesses: auth.get("userInfo").business.id },
      })
      .then((res) => {
        const data = res.data;

        setActivities(data);
      });
  }, []);

  //-------TEST-------TEST-------TEST-------TEST-------TEST-------TEST-------TEST-------TEST
  const groups = [
    { id: 1, title: "group 1" },
    { id: 2, title: "group 2" },
    { id: 3, title: "group 3" },
    { id: 4, title: "group 4" },
    { id: 5, title: "group 5" },
    { id: 6, title: "group 6" },
    { id: 7, title: "group 7" },
    { id: 8, title: "group 8" },
  ];

  const items = [
    {
      id: 1,
      group: 1,
      title: "item 1",
      start_time: moment(),
      end_time: moment().add(1, "hour"),
    },
    {
      id: 2,
      group: 2,
      title: "item 2",
      start_time: moment().add(-0.5, "hour"),
      end_time: moment().add(0.5, "hour"),
    },
    {
      id: 3,
      group: 1,
      title: "item 3",
      start_time: moment().add(2, "hour"),
      end_time: moment().add(3, "hour"),
    },
  ];
  //-------TEST-------TEST-------TEST-------TEST-------TEST-------TEST-------TEST-------TEST

  useEffect(() => {
    //Search for last character in URL to get ID of initiative
    var url = window.location.href;
    var idInUrl = url.length - 1;
    var id = url.charAt(idInUrl);

    axios
      .get(`https://api.beecon.dk/initiatives/` + id, {
        params: { businesses: auth.get("userInfo").business.id },
      })
      .then((res) => {
        const data = res.data;

        setInitiatives(data);
      });
  }, []);

  const options = (
    <React.Fragment>
      <Button color="primary">Tilføj</Button>
    </React.Fragment>
  );

  return (
    <SiteWrapper>
      <Page.Content>
        <Page.Header title={initiatives.title} />
        <Text>{initiatives.description}</Text>
      </Page.Content>
    </SiteWrapper>
  );
}

export default InitiativesShow;
