import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Page, Grid, Card, Alert, Button } from "tabler-react";

import SiteWrapper from "../SiteWrapper.js";

import { Formik, Form } from "formik";
import { TextInput } from "../../components/FormikFields/TextInput";

import { SelectInput } from "../../components/FormikFields/SelectInput";

import { useHistory } from "react-router-dom";
import headers from "../../utils/headers";
function CreateUser(props) {
  let history = useHistory();
  const [businesses, setBusinesses] = useState([]);

  const redirectAfterCreate = () => {
    history.push("/adminpanel/");
    window.location.reload(false);
  };

  useEffect(() => {
    axios
      .get(`https://api.beecon.dk/businesses`, {
        headers: headers,
      })
      .then((res) => {
        const data = res.data;
        setBusinesses(data);
      });
  }, []);

  const options = (
    <React.Fragment>
      <Button color="primary" type="submit">
        Gem
      </Button>
    </React.Fragment>
  );

  return (
    <SiteWrapper>
      <Formik
        initialValues={{
          form: {
            username: "",
            firstname: "",
            password: "",
            lastname: "",
            email: "",
            business: "",
            admin: false,
            businessadmin: false,
          },
        }}
        onSubmit={(values) => {
          axios
            .post(
              "https://api.beecon.dk/auth/local/register",
              {
                username: values.form.username,
                email: values.form.email,
                password: values.form.password,
                business: values.form.business,
                firstname: values.form.firstname,
                lastname: values.form.lastname,
              },
              {
                headers: headers,
              }
            )
            .then((response) => {
              redirectAfterCreate();
            })
            .catch((error) => {
              // Handle error.
              console.log("An error occurred:", error.response);
            });
        }}
      >
        <Page.Content>
          <Alert type="primary">
            Denne form er stadig under udvikling | Status:{" "}
            <strong>funktionel</strong>
          </Alert>
          <Form>
            <Page.Header options={options} />
            <Grid.Row cards={true}>
              <Card>
                <Card.Header>
                  <Card.Title>Tilføj bruger</Card.Title>
                </Card.Header>
                <Card.Body>
                  <TextInput name="form.firstname" label="Fornavn" />
                  <TextInput name="form.lastname" label="Efternavn" />
                  <TextInput name="form.username" label="Brugernavn" />
                  <TextInput name="form.email" label="E-mailadresse" />
                  <TextInput name="form.password" label="Adgangskode" />
                  <SelectInput
                    name="form.business"
                    label="Virksomhed"
                    as="select"
                    data={businesses}
                  />
                </Card.Body>
              </Card>
            </Grid.Row>
          </Form>
        </Page.Content>
      </Formik>
    </SiteWrapper>
  );
}

export default CreateUser;
