import * as React from "react";
import axios from "axios";
import { useEffect, useState } from "react";

import { Page, Badge, Grid, Card, Text, Button, Tag } from "tabler-react";

import auth from "../../utils/auth";

import DescriptionsStrat from "../../data/DescriptionsStrat";
import headers from "../../utils/headers";
import { useHistory } from "react-router-dom";

import SiteWrapper from "../SiteWrapper.js";
import LoadingCard from "../../components/LoadingCard";

const effortText = DescriptionsStrat.efforts.text;

function StatusBadge(status) {
  if (status === "Aktiv") {
    return (
      <Badge color="success" className="mr-1">
        Aktiv
      </Badge>
    );
  } else if (status === "Afventer") {
    return (
      <Badge color="warning" className="mr-1">
        Afventer
      </Badge>
    );
  } else {
    return (
      <Badge color="danger" className="mr-1">
        Afsluttet
      </Badge>
    );
  }
}

function Efforts() {
  let history = useHistory();
  const [efforts, setEfforts] = useState([]);
  const [loading, setLoading] = useState(true);

  const editClick = (id) => {
    history.push("/indsatser/" + id + "/edit");
  };

  const addClick = (id) => {
    history.push("/indsatser/tilfoej");
  };

  useEffect(() => {
    axios
      .get(
        `https://api.beecon.dk/efforts?_where[initiative.strategy.business]=` +
          auth.get("userInfo").business.id,
        {
          headers: headers,
        }
      )
      .then((res) => {
        const data = res.data;

        setEfforts(data);
        setLoading(false);
      });
  }, []);

  const options = (
    <React.Fragment>
      <Button color="primary" onClick={addClick}>
        Tilføj
      </Button>
    </React.Fragment>
  );

  return (
    <SiteWrapper>
      <Page.Content>
        <Page.Header title="Indsatser" options={options} />
        <Text className="mb-3 text-justify">{effortText}</Text>

        {!efforts.length && !loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10vh",
            }}
          >
            <div>
              <div style={{ textAlign: "center" }}>
                <img
                  alt=""
                  src="https://api.beecon.dk/uploads/telescope_7b7933ca9a.png"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    height: "150px",
                    marginTop: "10vh",
                  }}
                />
                <h4>Det ser tomt ud her</h4>
                <p>Klik tilføj for at oprette din første indsats</p>
              </div>
            </div>
          </div>
        )}
        <Grid.Row cards={true}>
          {!loading ? (
            efforts.map((effort) => (
              <>
                <Card key={effort.id}>
                  <Card.Status color="yellow" side />
                  <Card.Header>
                    <Card.Title>
                      {StatusBadge(effort.status)} {effort.title}
                    </Card.Title>
                    <Card.Options>
                      <Tag>{effort.responsible}</Tag>
                      <div style={{ paddingLeft: "8px" }}></div>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => editClick(effort.id)}
                      >
                        Rediger
                      </Button>

                      <Button
                        color="secondary"
                        size="sm"
                        className="ml-2"
                        onClick={(e) =>
                          window.confirm(
                            "Er du sikker på at du vil slette denne?"
                          ) &&
                          axios
                            .delete(
                              `https://api.beecon.dk/efforts/` + effort.id,
                              {
                                headers: headers,
                              }
                            )
                            .then((res) => {
                              window.location.reload(false);
                            })
                        }
                      >
                        Slet
                      </Button>
                    </Card.Options>
                  </Card.Header>
                  <Card.Body>
                    <div
                      dangerouslySetInnerHTML={{ __html: effort.description }}
                    />
                  </Card.Body>
                  <Card.Footer>
                    {effort.start_date} -{">"} {effort.end_date}
                  </Card.Footer>
                </Card>
              </>
            ))
          ) : (
            <LoadingCard />
          )}
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>
  );
}

export default Efforts;
