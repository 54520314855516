/* eslint-disable import/no-unresolved */
import React, { Component, useEffect, useState } from "react";
import Timeline from "react-timelines";

import { fill, hexToRgb, colourIsLight, nextColor } from "./utils";
import "react-timelines/lib/css/style.css";

import { START_YEAR, NUM_OF_YEARS, NUM_OF_TRACKS } from "./constants";

import { buildTimebar, buildTrack } from "./builder";

import axios from "axios";
import { useHistory } from "react-router-dom";

const now = new Date();
const efforts = [
  {
    id: 7,
    title: "Version 2",
    description: "<p><br></p>",
    start_date: "2021-01-01",
    end_date: "2021-02-15",
    status: "Aktiv",
    initiative: 10,
    responsible: "Jacob, Ömer og Marius",
    published_at: "2021-01-25T12:47:35.827Z",
    created_at: "2021-01-25T12:47:35.851Z",
    updated_at: "2021-01-25T12:47:45.798Z",
    KPIS: [],
  },
  {
    id: 8,
    title: "Kontinuerlig levering",
    description:
      "<p>Vi stræber efter hele tiden at lave små/store leverancer imellem versionerne for hele tiden at forbedre brugeroplevelsen.</p>",
    start_date: "2021-01-01",
    end_date: "2022-01-01",
    status: "Aktiv",
    initiative: 10,
    responsible: "Hele udviklingsholdet",
    published_at: "2021-01-25T12:56:24.893Z",
    created_at: "2021-01-25T12:56:24.905Z",
    updated_at: "2021-01-25T12:56:24.913Z",
    KPIS: [],
  },
];
const timebar = buildTimebar();

const MIN_ZOOM = 2;
const MAX_ZOOM = 20;

const InitiativeTimeline = (props) => {
  const [tracksLists, setTrackLists] = useState([]);
  const tracksById = fill(NUM_OF_TRACKS).reduce((acc, i) => {
    const track = buildTrack(i + 1);
    acc[track.id] = track;
    return acc;
  }, {});

  useEffect(() => {
    const formatData = async () => {
      props.data.efforts.map(async (effort, index) => {
        const data = await axios.get(
          `https://api.beecon.dk/efforts/${effort.id}`
        );

        const { activities } = data.data;

        setTrackLists((tracks) => {
          const latestTracks = activities.map((activity, ind) => {
            const bgColor = nextColor();
            const color = colourIsLight(...hexToRgb(bgColor))
              ? "#000000"
              : "#ffffff";

            return {
              id: effort.id,
              title: ind ? "" : effort.title,
              elements: [
                {
                  id: activity.id,
                  title: activity.title,
                  start: new Date(activity.start_date),
                  end: new Date(activity.end_date),
                  style: {
                    backgroundColor: `#${bgColor}`,
                    color,
                    borderRadius: "4px",
                    boxShadow: "1px 1px 0px rgba(0, 0, 0, 0.25)",
                    //textTransform: "capitalize",
                  },
                },
              ],
            };
          });
          return [...tracks, ...latestTracks];
        });
      });
    };
    formatData();
  }, []);
  const [state, setState] = useState({
    open: false,
    zoom: 2,
    // eslint-disable-next-line react/no-unused-state
    tracksById,
    tracks: Object.values(tracksById),
  });

  const handleToggleOpen = () => {
    setState((prev) => ({ ...prev, open: !open }));
  };

  const handleZoomIn = () => {
    setState((prev) => ({ ...prev, zoom: Math.min(zoom + 1, MAX_ZOOM) }));
  };

  const handleZoomOut = () => {
    setState((prev) => ({ ...prev, zoom: Math.max(zoom - 1, MIN_ZOOM) }));
  };
  console.log(tracksLists);
  const handleToggleTrackOpen = (track) => {
    setState((state) => {
      const tracksById = {
        ...state.tracksById,
        [track.id]: {
          ...track,
          isOpen: !track.isOpen,
        },
      };

      return {
        tracksById,
        tracks: Object.values(tracksById),
      };
    });
  };

  useEffect(() => {
    const trackDivs = document.getElementsByClassName("rt-track-key__entry");
    for (let div of trackDivs) {
      div.style.border = `${
        div.children[0] && div.children[0].innerHTML ? 1 : 0
      }px solid #eee`;
    }
  }, [tracksLists]);

  const { open, zoom, tracks } = state;
  const start = new Date(`${START_YEAR}`);
  const end = new Date(`${START_YEAR + NUM_OF_YEARS}`);
  let history = useHistory();

  return (
    <div className="app">
      <Timeline
        scale={{
          start,
          end,
          zoom,
          zoomMin: MIN_ZOOM,
          zoomMax: MAX_ZOOM,
        }}
        toggleOpen={handleToggleOpen}
        zoomIn={handleZoomIn}
        zoomOut={handleZoomOut}
        clickElement={(element) => {
          history.push("/aktiviteter/" + element.id + "/edit");
        }}
        clickTrackButton={(track) => {
          // eslint-disable-next-line no-alert
          alert(JSON.stringify(track));
        }}
        timebar={timebar}
        tracks={tracksLists}
        now={now}
        toggleTrackOpen={handleToggleTrackOpen}
        enableSticky
        scrollToNow
      />
    </div>
  );
};

export default InitiativeTimeline;
