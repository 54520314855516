/**
 *
 * AuthPage
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { findIndex, get, map, replace, set } from "lodash";
import { Link } from "react-router-dom";

import { Button } from "tabler-react";

import Input from "../../components/InputsIndex";

import Logo from "../../assets/BeeconLogoW.png";

// Utils
import auth from "../../utils/auth";
import request from "../../utils/request";

import form from "./forms.json";
import "./styles.css";

import { Hexagon } from "tiled-hexagons";

class AuthPage extends React.Component {
  state = { value: {}, errors: [], didCheckErrors: false };

  componentDidMount() {
    this.generateForm(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.authType !== this.props.match.params.authType) {
      this.generateForm(nextProps);
    }
  }

  getRequestURL = () => {
    let requestURL;

    switch (this.props.match.params.authType) {
      case "login":
        requestURL = "https://api.beecon.dk/auth/local";
        break;
      case "register":
        requestURL = "https://api.beecon.dk/auth/local/register";
        break;
      case "reset-password":
        requestURL = "https://api.beecon.dk/auth/reset-password";
        break;
      case "forgot-password":
        requestURL = "https://api.beecon.dk/auth/forgot-password";
        break;
      default:
    }

    return requestURL;
  };

  generateForm = (props) => {
    const params = props.location.search
      ? replace(props.location.search, "?code=", "")
      : props.match.params.id;
    this.setForm(props.match.params.authType, params);
  };

  handleChange = ({ target }) =>
    this.setState({
      value: { ...this.state.value, [target.name]: target.value },
    });

  handleSubmit = (e) => {
    e.preventDefault();
    const body = this.state.value;
    const requestURL = this.getRequestURL();

    // This line is required for the callback url to redirect your user to app
    if (this.props.match.params.authType === "forgot-password") {
      set(body, "url", "https://api.beecon.dk/auth/reset-password");
    }

    request(requestURL, { method: "POST", body: this.state.value })
      .then((response) => {
        auth.setToken(response.jwt, body.rememberMe);
        auth.setUserInfo(response.user, body.rememberMe);
        sessionStorage.setItem(
          "releaseNotesDismissed",
          response.user.releaseNotesDismissed
        );
        this.redirectUser();
      })
      .catch((err) => {
        // TODO handle errors for other views
        // This is just an example
        if (!err.response) {
        } else {
          const errors = [
            { name: "identifier", errors: [err.response.payload.message] },
          ];
          console.log(errors);
        }
      });
  };

  redirectUser = () => {
    this.props.history.push("/");
    window.location.reload(false);
  };

  /**
   * Function that allows to set the value to be modified
   * @param {String} formType the auth view type ex: login
   * @param {String} email    Optionnal
   */
  setForm = (formType, email) => {
    const value = get(form, ["data", formType], {});

    if (formType === "reset-password") {
      set(value, "code", email);
    }
    this.setState({ value });
  };

  /**
   * Check the URL's params to render the appropriate links
   * @return {Element} Returns navigation links
   */
  renderLink = () => {
    if (this.props.match.params.authType === "login") {
      return (
        <div>
          <Link to="/auth/forgot-password">Glemt adgangskode</Link>
          &nbsp;eller&nbsp;
          <Link to="/auth/register">registrer</Link>
        </div>
      );
    }

    return (
      <div>
        <Link to="/auth/login">Log ind</Link>
      </div>
    );
  };

  render() {
    const divStyle = { marginTop: ".9rem" };
    const inputs = get(form, ["views", this.props.match.params.authType], []);

    return (
      <div className="authPage">
        <div className="wrapper">
          <Hexagon
            img={Logo}
            sideLength={80}
            borderRadius={12}
            fill="#28282C"
            shadow="#C9DEF7"
            target="_blank" //open in new tab
          />
          <div className="headerContainer" style={{ textAlign: "center" }}>
            {/*<img src={Logo} alt="logo" />*/}
          </div>

          <div className="formContainer" style={divStyle}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12"></div>
              </div>

              <form onSubmit={this.handleSubmit}>
                <div className="row" style={{ textAlign: "start" }}>
                  {map(inputs, (input, key) => (
                    <Input
                      autoFocus={key === 0}
                      customBootstrapClass={get(input, "customBootstrapClass")}
                      didCheckErrors={this.state.didCheckErrors}
                      errors={get(
                        this.state.errors,
                        [
                          findIndex(this.state.errors, ["name", input.name]),
                          "errors",
                        ],
                        []
                      )}
                      key={get(input, "name")}
                      label={get(input, "label")}
                      name={get(input, "name")}
                      onChange={this.handleChange}
                      placeholder={get(input, "placeholder")}
                      type={get(input, "type")}
                      validations={{ required: true }}
                      value={get(this.state.value, get(input, "name"), "")}
                    />
                  ))}
                  <div className="col-md-12 buttonContainer">
                    <Button
                      color="primary"
                      style={{ width: "100%" }}
                      block
                      type="submit"
                    >
                      Indsend
                    </Button>
                    {/*<p>WORK IN PROGRESS::</p>
                    <div style={{ textAlign: 'center' }}>
                      <Hexagon
                        sideLength={60}
                        text="🚀"
                        textStyle={textStyle}
                        fill="#C9DEF7"
                        type="submit"
                      />
                   </div>*/}
                  </div>
                </div>
              </form>
              {/*<div className="linkContainer">{this.renderLink()}</div>*/}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const textStyle = {
  fontSize: "35px",
  fontWeight: 700,
  fill: "#fff",
};

AuthPage.defaultProps = {};
AuthPage.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default AuthPage;
