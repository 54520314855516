import * as React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Page, Grid, Card, Button } from "tabler-react";

import auth from "../../utils/auth";

import SiteWrapper from "../SiteWrapper.js";

import { Formik, Form, Field } from "formik";
import { TextInput } from "../../components/FormikFields/TextInput";
import { RichTextInput } from "../../components/FormikFields/RichTextInput";
import { DateInput } from "../../components/FormikFields/DateInput";
import { SelectField } from "../../components/FormikFields/SelectField";

import headers from "../../utils/headers";

import { useHistory } from "react-router-dom";

function CreateInitiative() {
  let history = useHistory();
  const [strategies, setStrategies] = useState([]);

  const redirectAfterCreate = () => {
    history.push("/initiativer/");
    window.location.reload(false);
  };

  const statuschoice = [
    { value: "Aktiv", label: "Aktiv" },
    { value: "Afventer", label: "Afventer" },
    { value: "Afsluttet", label: "Afsluttet" },
  ];

  useEffect(() => {
    axios
      .get(
        `https://api.beecon.dk/strategies?_where[business]=` +
          auth.get("userInfo").business.id,
        {
          headers: headers,
        }
      )
      .then((res) => {
        const data = res.data;

        setStrategies(data);
      });
  });

  const options = (
    <React.Fragment>
      <Button color="primary" type="submit">
        Gem
      </Button>
    </React.Fragment>
  );

  return (
    <SiteWrapper>
      <Formik
        initialValues={{
          form: {
            title: "",
            description:
              "<p>Fortæl hvilke 2-5 effektmål, der er for initiativet?</p><p>Med andre ord, hvornår har initiativet vist sit værd? Hvilken forretningsmæssig værdi forventer vi at dette leverer selv, eller bidrager til at levere?</p><p><br></p><p>Vær så specifik som mulig. Sæt tal på for at give en ide om hvorvidt vi gør noget 1% bedre eller 200% bedre.</p><p>Det handler om at give en indikation mere end at være 100% sikker på at tallet er rigtigt.</p><p><br></p><p>Det er set før at man er blevet enige om et initiativ, og dets effekt, nemlig: ”Øge vores sælgeres omsætning”. Alle er enige… men… den ene leder sidder og tænker ”Øge vores sælgeres omsætning med 5%” og den anden leder sidder og tænker ”Øge vores sælgeres omsætning med 60%”.</p><p><br></p><p>Det er derfor vigtigt at de bliver enige om at initiativet faktisk er sat i verdenen for at ”Øge vores sælgeres omsætning med 20%”. Så er alle på samme side, og er blevet enige om at det lyder som et fair mål at sigte efter.</p><p>Med det mål i baglommen, så er det også nemmere at beslutte, hvor mange ressourcer skal vi så bruge på det.</p><p><br></p><p>Skriv 2-5 effektmål, som giver en indikation om hvilken effekt vi forventer at skabe med dette initiativ.</p><p><br></p><p><strong>Effektmål:&nbsp;</strong></p><ol><li>...</li><li>...</li><li>...</li><li>...</li></ol><p><br></p><p>Se på disse effektmål, og spørg dig selv hvilke fokusområder du ser. Hvilke 2-5 fokusområder skal samlet set levere denne ønskede effekt?</p><p>Under hvert fokusområde ligger der nogle indsatser og milepæle, hvilket vi vil komme til i næste step.</p><p><br></p><p>Fokusområderne vil også rent visuelt komme til at fungere som spor i vores plan.</p><p><br></p><p><strong>Fokusområder:</strong></p><ol><li>...</li><li>...</li><li>...</li><li>...</li></ol><p><br></p><p><br></p><p>Stærkt gået! Så er du klar til at definere indsatser (inkl. milepæle).</p>",
            responsible: "",
            start_date: "",
            end_date: "",
            status: "",
            businesses: auth.get("userInfo").business.id,
            strategy: "",
          },
        }}
        onSubmit={(values) => {
          axios
            .post(`https://api.beecon.dk/initiatives/`, values.form, {
              headers: headers,
            })
            .then((res) => {
              redirectAfterCreate();
            });
        }}
      >
        <Page.Content>
          <Form>
            <Page.Header options={options} />
            <Grid.Row cards={true}>
              <Card>
                <Card.Header>
                  <Card.Title>Rediger</Card.Title>
                </Card.Header>
                <Card.Body>
                  <p>Status</p>
                  <Field
                    name="form.status"
                    component={SelectField}
                    options={statuschoice}
                  />

                  <TextInput name="form.title" label="Titel på dit initiativ" />
                  <RichTextInput
                    name="form.description"
                    onBlur={(e) => {
                      return;
                    }}
                    label="Beskrivelse af initiativet"
                  />

                  <DateInput
                    name="form.start_date"
                    label="Start dato for dit initiativ"
                  />
                  <DateInput
                    name="form.end_date"
                    label="Slut dato for dit initiativ"
                  />
                  <TextInput name="form.responsible" label="Ansvarlige" />
                  <p>Hvilken strategi tilhører dette initiativ?</p>

                  <Field
                    name="form.strategy"
                    component={SelectField}
                    options={strategies.map((strategy) => ({
                      value: strategy.id,
                      label: strategy.title,
                    }))}
                  />
                </Card.Body>
              </Card>
            </Grid.Row>
          </Form>
        </Page.Content>
      </Formik>
    </SiteWrapper>
  );
}

export default CreateInitiative;
