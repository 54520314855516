import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Page, Grid, GalleryCard } from "tabler-react";

import SiteWrapper from "../SiteWrapper.js";

import auth from "../../utils/auth";
import headers from "../../utils/headers";

function Documents() {
  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    axios
      .get(`https://api.beecon.dk/documents`, {
        params: { businesses: auth.get("userInfo").business.id },

        headers: headers,
      })
      .then((res) => {
        const data = res.data;
        console.log(res);
        setDocuments(data);
      });
  }, []);
  console.log(documents);
  return (
    <SiteWrapper>
      <Page.Content title="Dokumenter">
        <Grid.Row className="row-cards">
          {/* Laver en query på alle dokumenter der ligger under virksomheden med det ID som er knyttet til ens login */}
          {/* <Query query={GET_DOCUMENTS} id={auth.get("userInfo").business.id}>
            {({ loading, error, data }) => {
              if (loading) return <p>Loading...</p>;
              if (error) return <p>Error :(</p>;
              console.log(data.business.documents);
              return data.business.documents.map(({ id, title, doc }) => (
                <Grid.Col sm={6} lg={3} key={id}>
                  <GalleryCard>
                    <GalleryCard.Footer>
                      <GalleryCard.Details
                        avatarURL="https://image.flaticon.com/icons/png/512/337/337946.png"
                        fullName={title}
                      />
                      <GalleryCard.IconGroup>
                        <a
                          href={
                            "https://strapi-eyho.onrender.com" +
                            doc.url
                          }
                          download
                          target="_blank"
                        >
                          <GalleryCard.IconItem
                            name="download"
                            target="_blank"
                            right
                          />
                        </a>
                      </GalleryCard.IconGroup>
                    </GalleryCard.Footer>
                  </GalleryCard>
                </Grid.Col>
              ));
            }}
          </Query> */}
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>
  );
}

export default Documents;
