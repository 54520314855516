import {
    Card,
    Button
  } from "tabler-react";

  import ContentLoader from "react-content-loader"

export function LoadingCard(props) {
    return(

        <ContentLoader 
        speed={2}
        width={476}
        height={124}
        viewBox="0 0 476 124"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="-1" y="8" rx="3" ry="3" width="88" height="6" /> 
        <rect x="-1" y="26" rx="3" ry="3" width="52" height="6" /> 
        <rect x="0" y="56" rx="3" ry="3" width="410" height="6" /> 
        <rect x="0" y="72" rx="3" ry="3" width="380" height="6" /> 
        <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
      </ContentLoader>);
}

export default LoadingCard;