import React from 'react';
import { useField, Field } from 'formik';

export function SelectInput(props) {
  // this will return field props for an <input />
  // eslint-disable-line no-unused-vars
  const [field, meta, helpers] = useField(props.name);
  return (
    <>
      <p>
        <strong>Virksomhed</strong>
      </p>
      <Field {...props} {...field}>
        <option value="0">-</option>
        {props.data.map((business) => (
          <option value={business.id}>{business.title}</option>
        ))}
      </Field>

      {/*<Form.Group {...props}>
        <Form.Select {...field}>
          {props.data.map((business) => (
            <option key={business.id}>{business.title}</option>
          ))}
        </Form.Select>
          </Form.Group>*/}

      {meta.error && meta.touched && <div>{meta.error}</div>}
    </>
  );
}
