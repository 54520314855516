import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import auth from "../utils/auth";

const cache = new InMemoryCache();
const link = createHttpLink({
  uri: `https://api.beecon.dk/graphql`,
});

const client = new ApolloClient({
  cache,
  link,
  headers: {
    authorization: "Bearer " + auth.get("jwtToken"),
  },
});

export default client;
