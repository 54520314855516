import * as React from "react";

import { Page, Grid, Card, Button } from "tabler-react";

import SiteWrapper from "../SiteWrapper.js";

import { Formik, Form } from "formik";
import { TextInput } from "../../components/FormikFields/TextInput";
import { RichTextInput } from "../../components/FormikFields/RichTextInput";

import { useHistory } from "react-router-dom";

function EditBusiness() {
  // let history = useHistory();
  //Find ID i URL
  const url = window.location.pathname.match(
    /^\/adminpanel\/virksomhed\/(\d+)/
  );
  //var id = url
  const businessid = url[1];

  const options = (
    <React.Fragment>
      <Button color="primary" type="submit">
        Gem
      </Button>
    </React.Fragment>
  );

  return (
    <SiteWrapper>
      <Formik
        initialValues={{
          form: {
            title: "Luxidev",
            description: "fdsf",
          },
        }}
        onSubmit={(values) => {}}
      >
        <Page.Content>
          <Form>
            <Page.Header options={options} />
            <Grid.Row cards={true}>
              <Card>
                <Card.Header>
                  <Card.Title>Rediger</Card.Title>
                </Card.Header>
                <Card.Body>
                  <TextInput name="form.title" label="Titel på din strategi" />
                  <RichTextInput
                    name="form.description"
                    onBlur={(e) => {
                      return;
                    }}
                    label="Beskrivelse af strategien"
                  />
                </Card.Body>
              </Card>
            </Grid.Row>
          </Form>
        </Page.Content>
      </Formik>
    </SiteWrapper>
  );
}

export default EditBusiness;
