import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./c3jscustom.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import client from "./queries/apolloClient";
import { ApolloProvider } from "@apollo/client";

import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
const rootElement = document.getElementById("root");

LogRocket.init("1qsexe/beecon");
setupLogRocketReact(LogRocket);
if (rootElement) {
  ReactDOM.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </React.StrictMode>,
    rootElement
  );
} else {
  throw new Error("Could not find root element to mount to!");
}

reportWebVitals();
