import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";

import {
  Page,
  Avatar,
  Icon,
  Grid,
  Card,
  Text,
  Table,
  Alert,
  Progress,
  colors,
  Dropdown,
  Button,
  StampCard,
  Tag,
  StatsCard,
  ProgressCard,
  Badge,
} from "tabler-react";

import auth from "../../utils/auth";
import DescriptionsStrat from "../../data/DescriptionsStrat";
import LoadingCard from "../../components/LoadingCard";
import { Redirect, useHistory } from "react-router-dom";

import StyledContentLoader from "styled-content-loader";

import C3Chart from "react-c3js";

import SiteWrapper from "../SiteWrapper.js";
import headers from "../../utils/headers";

const strategyText = DescriptionsStrat.strategy.text;

function Strategy() {
  let history = useHistory();
  const [strategies, setStrategies] = useState([]);
  const [loading, setLoading] = useState(true);

  const editClick = (id) => {
    history.push("/strategi/" + id + "/edit");
  };

  const addClick = (id) => {
    history.push("/strategi/tilfoej");
  };

  useEffect(() => {
    axios
      .get(`https://api.beecon.dk/strategies`, {
        params: { business: auth.get("userInfo").business.id },

        headers: headers,
      })
      .then((res) => {
        const data = res.data;
        setStrategies(data);
        setLoading(false);
      });
  }, []);

  const options = (
    <React.Fragment>
      <Button color="primary" onClick={addClick}>
        Tilføj
      </Button>
    </React.Fragment>
  );

  return (
    <SiteWrapper>
      <Page.Content>
        <Page.Header title="Strategi" options={options} />
        <Text style={{ marginBottom: 3 + "vh" }}>{strategyText}</Text>
        {!strategies.length && !loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10vh",
            }}
          >
            <div>
              <div style={{ textAlign: "center" }}>
                <img
                  alt=""
                  src="https://api.beecon.dk/uploads/telescope_7b7933ca9a.png"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    height: "150px",
                    marginTop: "10vh",
                  }}
                />
                <h4>Det ser tomt ud her</h4>
                <p>Klik tilføj for at oprette din første strategi</p>
              </div>
            </div>
          </div>
        )}
        <Grid.Row cards={true}>
          {!loading ? (
            strategies.map((strategy) => (
              <>
                <Card key={strategy.id}>
                  <Card.Status color="red" side />
                  <Card.Header>
                    <Card.Title>{strategy.title}</Card.Title>
                    <Card.Options>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => editClick(strategy.id)}
                      >
                        Rediger
                      </Button>
                      <Button
                        color="secondary"
                        size="sm"
                        className="ml-2"
                        onClick={(e) =>
                          window.confirm(
                            "Hvis du sletter denne strategi, vil alle initiativer, indsatser og aktiveter der er tilknyttet, også blive slettet."
                          ) &&
                          axios
                            .delete(
                              `https://api.beecon.dk/strategies/` + strategy.id,
                              {
                                headers: headers,
                              }
                            )
                            .then((res) => {
                              window.location.reload(false);
                            })
                        }
                      >
                        Slet
                      </Button>
                    </Card.Options>
                  </Card.Header>
                  <Card.Body>
                    <div
                      dangerouslySetInnerHTML={{ __html: strategy.description }}
                    />
                  </Card.Body>
                  <Card.Footer>
                    {strategy.start_date} -{">"} {strategy.end_date}
                  </Card.Footer>
                </Card>
              </>
            ))
          ) : (
            <LoadingCard />
          )}
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>
  );
}

export default Strategy;
