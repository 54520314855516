import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Page, Grid, Card, Button } from "tabler-react";

import { Form as FormTabler } from "tabler-react";

import SiteWrapper from "../SiteWrapper.js";
import { SelectField } from "../../components/FormikFields/SelectField";

import { Formik, Form, Field } from "formik";
import { TextInput } from "../../components/FormikFields/TextInput";
import { RichTextInput } from "../../components/FormikFields/RichTextInput";
import { DateInput } from "../../components/FormikFields/DateInput";
import auth from "../../utils/auth";
import { useHistory } from "react-router-dom";
import headers from "../../utils/headers";

function EditActivity() {
  let history = useHistory();

  const [efforts, setEfforts] = useState([]);
  //Find ID i URL
  const url = window.location.pathname.match(/^\/aktiviteter\/(\d+)/);
  //var id = url
  const activityid = url[1];

  const [activity, setActivity] = useState({
    title: "",
    description: "",
    start_date: "",
    responsible: "",
    end_date: "",
    effort: "",
  });

  useEffect(() => {
    axios
      .get(`https://api.beecon.dk/activities/` + activityid, {
        headers: headers,
      })
      .then((res) => {
        const data = res.data;

        setActivity(data);
      });
  }, [activityid]);

  useEffect(() => {
    axios
      .get(
        `https://api.beecon.dk/efforts?_where[initiative.strategy.business]=` +
          auth.get("userInfo").business.id,
        {
          headers: headers,
        }
      )
      .then((res) => {
        const data = res.data;

        setEfforts(data);
      });
  });

  const choices = efforts.map((effort) => ({
    value: effort.id,
    label: effort.title,
  }));

  const options = (
    <React.Fragment>
      <Button color="primary" type="submit">
        Gem
      </Button>
    </React.Fragment>
  );

  const statuschoice = [
    { value: "Aktiv", label: "Aktiv" },
    { value: "Afventer", label: "Afventer" },
  ];

  return (
    <SiteWrapper>
      <Formik
        enableReinitialize
        initialValues={{
          form: {
            title: activity.title,
            description: activity.description,
            start_date: activity.start_date,
            end_date: activity.end_date,
            responsible: activity.responsible,
            finished: activity.finished,
            status: activity.status,
            effort: activity.effort.id,
          },
        }}
        onSubmit={(values) => {
          axios
            .put(
              `https://api.beecon.dk/activities/` + activityid,
              values.form,
              {
                headers: headers,
              }
            )
            .then((res) => {
              setTimeout(() => {
                history.push("/aktiviteter/");
              }, 1000);
            });
        }}
      >
        <Page.Content>
          <Form>
            <Page.Header options={options} />
            <Grid.Row cards={true}>
              <Card>
                <Card.Header>
                  <Card.Title>Rediger</Card.Title>
                </Card.Header>
                <Card.Body>
                  {/*<p>
                    DETTE STATUS INPUT ER UNDER UDVIKLING. BRUG DET NEDENFOR
                    ISTEDET
                  </p>
                  <FormTabler.Group>
                    <FormTabler.SelectGroup>
                      <FormTabler.SelectGroupItem
                        label="Aktiv"
                        name="form.status"
                        value="aktiv"
                      />
                      <FormTabler.SelectGroupItem
                        label="Afventer"
                        name="form.status"
                        value="afventer"
                      />
                      <FormTabler.SelectGroupItem
                        label="Afsluttet"
                        name="form.status"
                        value="afsluttet"
                      />
                    </FormTabler.SelectGroup>
                  </FormTabler.Group>*/}
                  <p>Status</p>
                  <Field
                    name="form.status"
                    component={SelectField}
                    options={statuschoice}
                  />
                  <TextInput name="form.title" label="Titel på din aktivitet" />
                  <RichTextInput
                    name="form.description"
                    onBlur={(e) => {
                      return;
                    }}
                    label="Beskrivelse af aktivitet"
                  />
                  <DateInput
                    name="form.start_date"
                    label="Start dato for din aktivitet"
                  />
                  <DateInput
                    name="form.end_date"
                    label="Slut dato for din aktivitet"
                  />
                  <TextInput name="form.responsible" label="Ansvarlige" />
                  <p>Hvilken indsats tilhører denne aktivitet?</p>
                  <Field
                    name="form.effort"
                    component={SelectField}
                    options={choices}
                  />
                </Card.Body>
              </Card>
            </Grid.Row>
          </Form>
        </Page.Content>
      </Formik>
    </SiteWrapper>
  );
}

export default EditActivity;
