import React from "react";
import useApiRequest from "../../utils/store";
import { Grid } from "tabler-react";
import StatCard from "../StatCard";
import auth from "../../utils/auth";

function EffortRow() {
  const { data, error, isLoaded } = useApiRequest(
    ` https://api.beecon.dk/efforts?_where[initiative.strategy.business]=` +
      auth.get("userInfo").business.id
  );

  let ActiveOnly = data.filter(({ status }) => status === "Aktiv");
  let AwaitingOnly = data.filter(({ status }) => status === "Afventer");
  let FinishedOnly = data.filter(({ status }) => status === "Afsluttet");
  let def = 0;

  return (
    <Grid.Row cards deck>
      <Grid.Col width={6} sm={4} lg={4}>
        <StatCard
          color="yellow"
          name="Aktive indsatser"
          value={ActiveOnly.length}
          percentage={
            ActiveOnly.length
              ? Math.round(
                  ((ActiveOnly.length / data.length) * 100 + Number.EPSILON) *
                    10
                ) /
                  10 +
                "%"
              : def + "%"
          }
        />
      </Grid.Col>
      <Grid.Col width={6} sm={4} lg={4}>
        <StatCard
          color="yellow"
          value={AwaitingOnly.length}
          name="Afventende indsatser"
          percentage={
            AwaitingOnly.length
              ? Math.round(
                  ((AwaitingOnly.length / data.length) * 100 + Number.EPSILON) *
                    10
                ) /
                  10 +
                "%"
              : def + "%"
          }
        />
      </Grid.Col>
      <Grid.Col width={6} sm={4} lg={4}>
        <StatCard
          color="yellow"
          value={FinishedOnly.length}
          name="Afsluttede indsatser"
          percentage={
            FinishedOnly.length
              ? Math.round(
                  ((FinishedOnly.length / data.length) * 100 + Number.EPSILON) *
                    10
                ) /
                  10 +
                "%"
              : def + "%"
          }
        />
      </Grid.Col>
    </Grid.Row>
  );
}
export default EffortRow;
