import * as React from "react";
import axios from "axios";
import { Page, Grid, Card, Button } from "tabler-react";

import auth from "../../utils/auth";

import SiteWrapper from "../SiteWrapper.js";

import * as Yup from "yup";

import { Formik, Form } from "formik";
import { TextInput } from "../../components/FormikFields/TextInput";
import { RichTextInput } from "../../components/FormikFields/RichTextInput";
import { DateInput } from "../../components/FormikFields/DateInput";
import headers from "../../utils/headers";

import { useHistory } from "react-router-dom";

function CreateStrategy() {
  let history = useHistory();

  const redirectAfterCreate = () => {
    history.push("/strategi/");
    window.location.reload(false);
  };

  const StrategySchema = Yup.object().shape({
    title: Yup.string()

      .min(2, "Too Short!")

      .max(50, "Too Long!")

      .required("Required"),

    start_date: Yup.string()

      .min(2, "Too Short!")

      .max(50, "Too Long!")

      .required("Required"),

    end_date: Yup.string().email("Invalid email").required("Required"),
  });

  const options = (
    <React.Fragment>
      <Button color="primary" type="submit">
        Gem
      </Button>
    </React.Fragment>
  );

  return (
    <SiteWrapper>
      <Formik
        initialValues={{
          form: {
            title: "",
            description: "",
            start_date: "",
            end_date: "",
            business: auth.get("userInfo").business.id,
          },
        }}
        onSubmit={(values) => {
          axios
            .post(`https://api.beecon.dk/strategies/`, values.form, {
              headers: headers,
            })
            .then((res) => {
              redirectAfterCreate();
            });
        }}
      >
        <Page.Content>
          <Form>
            <Page.Header options={options} />
            <Grid.Row cards={true}>
              <Card>
                <Card.Header>
                  <Card.Title>Rediger</Card.Title>
                </Card.Header>
                <Card.Body>
                  <TextInput name="form.title" label="Titel på din strategi" />
                  <RichTextInput
                    name="form.description"
                    onBlur={(e) => {
                      return;
                    }}
                    label="Beskrivelse af strategien"
                  />
                  <DateInput
                    name="form.start_date"
                    label="Start dato for din strategi"
                  />
                  <DateInput
                    name="form.end_date"
                    label="Slut dato for din strategi"
                  />
                </Card.Body>
              </Card>
            </Grid.Row>
          </Form>
        </Page.Content>
      </Formik>
    </SiteWrapper>
  );
}

export default CreateStrategy;
