import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Page, Grid, Card, Badge, Text, Button, Tag } from "tabler-react";

import auth from "../../utils/auth";
import headers from "../../utils/headers";
import DescriptionsStrat from "../../data/DescriptionsStrat";
import InitiativeTimeline from "../../components/Timeline";
import { useHistory } from "react-router-dom";

import SiteWrapper from "../SiteWrapper.js";
import LoadingCard from "../../components/LoadingCard";
import Initiatives from "./ShowInitiative";
import { id } from "date-fns/locale";
import { Divider } from "rsuite";

export function InitiativeShow() {
  const [initiative, setInitiative] = useState([]);
  const [loading, setLoading] = useState([]);

  let history = useHistory();
  //Find ID i URL
  const url = window.location.pathname.match(/^\/initiativer\/(\d+)/);
  const initid = url[1];
  console.log(initid);

  const goBack = () => {
    history.push("/initiativer/");
  };

  useEffect(() => {
    axios
      .get(`https://api.beecon.dk/initiatives/` + initid, {
        //params: { businesses: auth.get("userInfo").business.id },
        headers: headers,
      })
      .then((res) => {
        const data = res.data;

        setInitiative(data);
        setLoading(false);
      });
  }, []);

  return (
    <SiteWrapper>
      <Page.Content>
        {!loading ? (
          <>
            <Button color="primary" onClick={() => goBack()}>
              Tilbage
            </Button>

            <h2 style={{ paddingTop: 20 }}>{initiative.title}</h2>
            <Tag color="indigo">{initiative.responsible}</Tag>
            <p style={{ color: "grey", paddingTop: "15px" }}>
              {initiative.start_date} -{">"} {initiative.end_date}
            </p>
            {/*<p style={{ fontWeight: 900, fontSize: "20px" }}>Beskrivelse</p>
            <p dangerouslySetInnerHTML={{ __html: initiative.description }}></p>*/}
            <Card
              title="Beskrivelse"
              body={
                <div
                  dangerouslySetInnerHTML={{
                    __html: initiative.description,
                  }}
                />
              }
            />
            <InitiativeTimeline data={initiative} />
          </>
        ) : (
          <LoadingCard />
        )}
      </Page.Content>
    </SiteWrapper>
  );
}

export default InitiativeShow;
