import * as React from "react";
import axios from "axios";
import { useState, useEffect } from "react";

import { Page, Grid, Card, Button } from "tabler-react";

import auth from "../../utils/auth";

import SiteWrapper from "../SiteWrapper.js";
import { SelectField } from "../../components/FormikFields/SelectField";

import { Formik, Form, Field } from "formik";
import { TextInput } from "../../components/FormikFields/TextInput";
import { RichTextInput } from "../../components/FormikFields/RichTextInput";
import { DateInput } from "../../components/FormikFields/DateInput";
import headers from "../../utils/headers";

import { useHistory } from "react-router-dom";

function CreateActivity() {
  let history = useHistory();
  const [efforts, setEfforts] = useState([]);

  const redirectAfterCreate = () => {
    history.push("/aktiviteter/");
    window.location.reload(false);
  };

  const statuschoice = [
    { value: "Aktiv", label: "Aktiv" },
    { value: "Afventer", label: "Afventer" },
  ];

  useEffect(() => {
    axios
      .get(
        `https://api.beecon.dk/efforts?_where[initiative.strategy.business]=` +
          auth.get("userInfo").business.id,
        {
          headers: headers,
        }
      )
      .then((res) => {
        const data = res.data;

        setEfforts(data);
      });
  });

  const options = (
    <React.Fragment>
      <Button color="primary" type="submit">
        Gem
      </Button>
    </React.Fragment>
  );

  return (
    <SiteWrapper>
      <Formik
        initialValues={{
          form: {
            title: "",
            description: "",
            start_date: "",
            end_date: "",
            responsible: "",
            status: "",
            businesses: auth.get("userInfo").business.id,
          },
        }}
        onSubmit={(values) => {
          axios
            .post(`https://api.beecon.dk/activities/`, values.form, {
              headers: headers,
            })
            .then((res) => {
              redirectAfterCreate();
            });
        }}
      >
        <Page.Content>
          <Form>
            <Page.Header options={options} />
            <Grid.Row cards={true}>
              <Card>
                <Card.Header>
                  <Card.Title>Rediger</Card.Title>
                </Card.Header>
                <Card.Body>
                  <p>Status</p>
                  <Field
                    name="form.status"
                    component={SelectField}
                    options={statuschoice}
                  />
                  <TextInput name="form.title" label="Titel på din aktivitet" />
                  <RichTextInput
                    name="form.description"
                    onBlur={(e) => {
                      return;
                    }}
                    label="Beskrivelse af aktivitet"
                  />
                  <DateInput
                    name="form.start_date"
                    label="Start dato for din aktivitet"
                  />
                  <DateInput
                    name="form.end_date"
                    label="Slut dato for din aktivitet"
                  />
                  <TextInput name="form.responsible" label="Ansvarlige" />
                  <p>Hvilken indsats tilhører dette aktivitet?</p>
                  <Field
                    name="form.effort"
                    component={SelectField}
                    options={efforts.map((effort) => ({
                      value: effort.id,
                      label: effort.title,
                    }))}
                  />
                </Card.Body>
              </Card>
            </Grid.Row>
          </Form>
        </Page.Content>
      </Formik>
    </SiteWrapper>
  );
}

export default CreateActivity;
