import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

//Sider
import HomePage from "./views/HomePage/HomePage";

//Strategi
import StrategyPage from "./views/StrategyPage/StrategyPage";
import EditStrategyPage from "./views/StrategyPage/EditStrategyPage";
import CreateStrategyKPI from "./views/StrategyPage/CreateKPIPage";
import CreateStrategy from "./views/StrategyPage/CreateStrategyPage";

import InitiativesShow from "./views/InitiativesPage/ShowInitiative";

import InitiativesPage from "./views/InitiativesPage/InitiativesPage";
import InitiativeShow from "./views/InitiativesPage/InitiativeShow";
import CreateInitiativePage from "./views/InitiativesPage/CreateInitiativePage";
import EditInitiativesPage from "./views/InitiativesPage/EditInitiativePage";
import CreateInitiativeKPI from "./views/InitiativesPage/CreateKPIPage";

import EffortsPage from "./views/EffortsPage/EffortsPage";
import CreateEffortPage from "./views/EffortsPage/CreateEffortPage";
import EditEffortPage from "./views/EffortsPage/EditEffortPage";
import CreateEffortKPI from "./views/EffortsPage/CreateKPIPage";

import ActivitiesPage from "./views/ActivitiesPage/ActivitiesPage";
import CreateActivityPage from "./views/ActivitiesPage/CreateActivityPage";
import EditActivityPage from "./views/ActivitiesPage/EditActivityPage";

import DocumentsPage from "./views/DocumentsPage/DocumentsPage";
import AuthPage from "./views/AuthPage/AuthPage";
import ProfilePage from "./views/ProfilePage/ProfilePage";

import AdminPanel from "./views/AdminPanel/AdminPanel";
import EditBusinessPage from "./views/AdminPanel/EditBusinessPage";
import CreateBusinessPage from "./views/AdminPanel/CreateBusinessPage";
import CreateUserPage from "./views/AdminPanel/CreateUserPage";

import Error400Page from "./views/ErrorPages/Error400Page";
import Error401Page from "./views/ErrorPages/Error401Page";
import Error403Page from "./views/ErrorPages/Error403Page";
import Error404Page from "./views/ErrorPages/Error404Page";
import Error500Page from "./views/ErrorPages/Error500Page";
import Error503Page from "./views/ErrorPages/Error503Page";

//Feedback (https://feeder.sh/)
import Feedback from "feeder-react-feedback";
import "feeder-react-feedback/dist/feeder-react-feedback.css";

//Route Restrictions
import AdminRoute from "./utils/RouteRestrictions/AdminRoute";
import PrivateRoute from "./utils/RouteRestrictions/PrivateRoute";
import LogOut from "./utils/logOut";

import "tabler-react/dist/Tabler.css";
// import CreateBusiness from "./views/AdminPanel/CreateBusinessPage";

/*

<PrivateRoute> kræver at man er logged ind for at tilgå dem
<Feedback> er vores feedback plugin som er tilkoblet https://feeder.sh/

*/

function App() {
  return (
    <React.StrictMode>
      <Router>
        <Switch>
          <Route path="/auth/:authType/:id?" component={AuthPage} />
          <PrivateRoute exact path="/" component={HomePage} />
          <PrivateRoute exact path="/strategi" component={StrategyPage} />
          <PrivateRoute
            exact
            path="/initiativer/:id/show"
            component={InitiativeShow}
          />
          <PrivateRoute
            exact
            path="/strategi/:id/edit"
            component={EditStrategyPage}
          />
          <PrivateRoute
            exact
            path="/strategi/tilfoej"
            component={CreateStrategy}
          />
          <PrivateRoute
            exact
            path="/strategi/:id/edit/kpi/tilfoej"
            component={CreateStrategyKPI}
          />
          <AdminRoute exact path="/adminpanel" component={AdminPanel} />
          <AdminRoute
            exact
            path="/adminpanel/virksomhed/:id/edit"
            component={EditBusinessPage}
          />
          <AdminRoute
            exact
            path="/adminpanel/virksomhed/tilfoej"
            component={CreateBusinessPage}
          />
          <AdminRoute
            exact
            path="/adminpanel/bruger/tilfoej"
            component={CreateUserPage}
          />
          <PrivateRoute exact path="/initiativer" component={InitiativesPage} />
          <PrivateRoute
            exact
            path="/initiativer/:id/edit"
            component={EditInitiativesPage}
          />
          <PrivateRoute
            exact
            path="/initiativer/tilfoej"
            component={CreateInitiativePage}
          />
          <PrivateRoute
            exact
            path="/initiativer/:id/edit/kpi/tilfoej"
            component={CreateInitiativeKPI}
          />
          <PrivateRoute exact path="/indsatser" component={EffortsPage} />
          <PrivateRoute
            exact
            path="/indsatser/:id/edit"
            component={EditEffortPage}
          />
          <PrivateRoute
            exact
            path="/indsatser/tilfoej"
            component={CreateEffortPage}
          />
          <PrivateRoute
            exact
            path="/indsatser/:id/edit/kpi/tilfoej"
            component={CreateEffortKPI}
          />
          <PrivateRoute exact path="/aktiviteter" component={ActivitiesPage} />
          <PrivateRoute
            exact
            path="/aktiviteter/:id/edit"
            component={EditActivityPage}
          />
          <PrivateRoute
            exact
            path="/aktiviteter/tilfoej"
            component={CreateActivityPage}
          />
          <PrivateRoute exact path="/dokumenter" component={DocumentsPage} />
          <PrivateRoute exact path="/profil" component={ProfilePage} />
          <Route exact path="/logout" component={LogOut} />
          <Route exact path="/400" component={Error400Page} />
          <Route exact path="/401" component={Error401Page} />
          <Route exact path="/403" component={Error403Page} />
          <Route exact path="/404" component={Error404Page} />
          <Route exact path="/500" component={Error500Page} />
          <Route exact path="/503" component={Error503Page} />
          <Route path="/*" component={Error404Page} />
        </Switch>
      </Router>
      <Feedback
        projectId="5fbb2a2caa6288000437b26d"
        email
        postSubmitButtonMsg="Tak!"
        submitButtonMsg="Send feedback"
        feedbackTypes={["generelt", "fejl", "forslag"]}
      />
    </React.StrictMode>
  );
}

export default App;
