import * as React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Page, Grid, Card, Text, Button, Tag, Badge, Icon } from "tabler-react";

import auth from "../../../utils/auth";

import DescriptionsStrat from "../../../data/DescriptionsStrat";

import { useHistory } from "react-router-dom";
import headers from "../../../utils/headers";

import useApiRequest from "../../../utils/store";
import { FlexboxGrid } from "rsuite";
import LoadingCard from "../../../components/LoadingCard";
const activitiesText = DescriptionsStrat.activities.text;

function StatusBadge(status) {
  if (status === "Aktiv") {
    return (
      <Badge color="success" className="mr-1">
        Aktiv
      </Badge>
    );
  } else if (status === "Afventer") {
    return (
      <Badge color="warning" className="mr-1">
        Afventer
      </Badge>
    );
  } else {
    return (
      <Badge color="danger" className="mr-1">
        Afsluttet
      </Badge>
    );
  }
}

function FinishedActivities() {
  let history = useHistory();
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const { data, error, isLoaded } = useApiRequest(
    `  https://api.beecon.dk/activities?_where[effort.initiative.strategy.business]=` +
      auth.get("userInfo").business.id
  );

  const editClick = (id) => {
    history.push("/aktiviteter/" + id + "/edit");
  };

  const addClick = (id) => {
    history.push("/aktiviteter/tilfoej");
  };

  const statuschoice = [
    { value: "Aktiv", label: "Aktiv" },
    { value: "Afventer", label: "Afventer" },
    { value: "Afsluttet", label: "Afsluttet" },
  ];

  useEffect(() => {
    axios
      .get(
        `https://api.beecon.dk/activities?_where[effort.initiative.strategy.business]=` +
          auth.get("userInfo").business.id +
          "&_where[finished]=true",
        {
          headers: headers,
        }
      )
      .then((res) => {
        const data = res.data;

        setActivities(data);
        setLoading(false);
      });
  }, []);

  const options = (
    <React.Fragment>
      <Button color="primary" onClick={addClick}>
        Tilføj
      </Button>
    </React.Fragment>
  );
  return (
    <>
      <h4 style={{ paddingTop: "25px" }}>Færdige aktiviteter</h4>

      {!loading ? (
        activities.map((activity) => (
          <>
            <Card key={activity.id} isCollapsed={true}>
              <Card.Status color="teal" side />
              <Card.Header>
                <Card.Title>
                  <Badge color="info" className="mr-1">
                    Færdig
                  </Badge>{" "}
                  {activity.title}
                </Card.Title>
                <Card.Options>
                  <Tag>{activity.responsible}</Tag>
                  <div style={{ paddingLeft: "8px" }}></div>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => editClick(activity.id)}
                  >
                    Rediger
                  </Button>
                  <Button
                    color="cyan"
                    size="sm"
                    className="ml-2"
                    onClick={(e) =>
                      axios
                        .put(
                          `https://api.beecon.dk/activities/` + activity.id,
                          {
                            finished: false,
                            headers: headers,
                          }
                        )
                        .then((res) => {
                          window.location.reload(false);
                        })
                    }
                  >
                    Ikke færdig
                  </Button>
                  <Button
                    color="danger"
                    size="sm"
                    className="ml-2"
                    onClick={(e) =>
                      window.confirm(
                        "Er du sikker på at du vil slette denne aktivitet?"
                      ) &&
                      axios
                        .delete(
                          `https://api.beecon.dk/activities/` + activity.id,
                          {
                            headers: headers,
                          }
                        )
                        .then((res) => {
                          window.location.reload(false);
                        })
                    }
                  >
                    Slet
                  </Button>
                  {/*<Button
                        color="success"
                        size="sm"
                        className="ml-2"
                        onClick={(e) =>
                          window.confirm(
                            "Are you sure you wish to clear the page?"
                          ) &&
                          axios
                            .delete(
                              `https://api.beecon.dk/activities/` + activity.id,
                              {
                                headers: headers,
                              }
                            )
                            .then((res) => {
                              window.location.reload(false);
                            })
                        }
                      >
                        Færdig
                      </Button>*/}
                </Card.Options>
              </Card.Header>
              <Card.Body>
                <div
                  dangerouslySetInnerHTML={{ __html: activity.description }}
                />
              </Card.Body>

              <Card.Footer>
                <strong>{activity.effort.title}</strong>
                {" | "}
                {activity.start_date} -{">"} {activity.end_date}
              </Card.Footer>
            </Card>
          </>
        ))
      ) : (
        <LoadingCard />
      )}
    </>
  );
}

export default FinishedActivities;
