import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Page, Grid, Card, Alert, colors, Button, Header } from "tabler-react";
import StatCard from "../../components/StatCard";

import C3Chart from "react-c3js";
import KPI_Tracker from "../../components/MainGraphs/kpiTracker";

import SiteWrapper from "../SiteWrapper.js";

import ActivitiesRow from "../../components/3x3Dashboard/Activities";
import EffortsRow from "../../components/3x3Dashboard/Efforts";
import InitiativesRow from "../../components/3x3Dashboard/Initiatives";
import auth from "../../utils/auth";
import useApiRequest from "../../utils/store";
import { useHistory } from "react-router-dom";
import headers from "../../utils/headers";

import moment from "moment";
import ActivityTracker from "../../components/MainGraphs/activityTracker";

function Home() {
  let history = useHistory();
  const [strategies, setStrategies] = useState(true);
  const firstStrategyBtn = () => {
    history.push("/strategi/tilfoej");
  };

  const [activeStrategy, setActiveStrategy] = useState("");
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);

  const releaseNotesDismiss = () => {
    axios
      .put(
        `https://api.beecon.dk/users/` + auth.get("userInfo").id,
        { releaseNotesDismissed: true },
        {
          headers: headers,
        }
      )
      .then(() => {
        console.log("Dismissed");
        sessionStorage.setItem("releaseNotesDismissed", true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    axios
      .get(`https://api.beecon.dk/strategies`, {
        params: { business: auth.get("userInfo").business.id },
        headers: headers,
      })
      .then((res) => {
        const data = res.data;
        if (data.length < 1) {
          setStrategies(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    axios
      .get(`https://api.beecon.dk/strategies`, {
        params: { business: auth.get("userInfo").business.id },
        headers: headers,
      })
      .then((res) => {
        const data = res.data;
        if (data[0]) {
          setActiveStrategy(data[0]);
          setStartDate(new Date(data[0].start_date));
          setEndDate(new Date(data[0].end_date));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <SiteWrapper>
      <Page.Content>
        {console.log(auth.get("userInfo"))}
        {console.log(sessionStorage.getItem("releaseNotesDismissed"))}

       { /*{sessionStorage.getItem("releaseNotesDismissed") ? (
          <Alert
            type="primary"
            isDismissible
            onDismissClick={releaseNotesDismiss}
          >
            <strong>
              Vi har netop tilføjet lidt nyt på Beecon platformen. God
              fornøjelse med det.
            </strong>
            <br /> - Under hvert initiativ, kan du nu se en tidslinje med de
            indsatser og aktiviteter der hører til
            <br /> - Vi har gjort det lidt nemmere for dig at tilføje og
            redigere initiativer, indsatser og aktiviteter
            <br /> - Så har vi selvfølgelig også lavet en masse bag om
            platformen som holder den godt kørende
            <br /> <strong>Kommer meget snart</strong>
            <br /> - Du vil på startsiden (hjem) få et bedre overblik over din
            fremgang.
            <br />
            <br /> Skriv endelig til os hvis du har forslag til hvordan vi gør
            strategiarbejdet mere overskueligt!
            <br /> <i>platform@beecon.dk</i>
          </Alert>
       ) : null} */}

        {!strategies && (
          <Alert type="primary">
            <Header.H4>Kom igang</Header.H4>
            <p>Du har ikke opsat en strategi.</p>
            <Button.List>
              <Button
                color="success"
                RootComponent="button"
                onClick={firstStrategyBtn}
              >
                Lav den første strategi
              </Button>
            </Button.List>
          </Alert>
        )}

        {/*<Grid.Row cards deck>
          <Grid.Col>
            <Card title="Strategi" body="A" />
          </Grid.Col>
          <Grid.Col>
            <Card
              title="Initiativer"
              body={`Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam
    deleniti fugit incidunt.`}
            />
          </Grid.Col>
          <Grid.Col>
            <Card title="Indsatser" body="C" />
          </Grid.Col>
          <Grid.Col>
            <Card title="Aktiviteter" body="C" />
          </Grid.Col>
        </Grid.Row>
*/}
        <Grid.Row>
          {/*<Grid.Col lg={6}>
            <KPI_Tracker />
          </Grid.Col>

          <Grid.Col lg={6}>
            <ActivityTracker />
</Grid.Col>*/}

          <div>
            <InitiativesRow />
            <EffortsRow />
            <ActivitiesRow />
          </div>
          <Grid.Col>
            <Card statusColor="red">
              <Card.Header>
                <Card.Title>Strategi: {activeStrategy.title}</Card.Title>
                <Card.Options>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => {
                      history.push("/initiativer");
                    }}
                  >
                    Gå til initiativer
                  </Button>
                </Card.Options>
              </Card.Header>
              <Card.Body>
                <div
                  dangerouslySetInnerHTML={{
                    __html: activeStrategy.description,
                  }}
                />
              </Card.Body>
              <Card.Footer>
                {activeStrategy.start_date} -{">"} {activeStrategy.end_date}
              </Card.Footer>
            </Card>
          </Grid.Col>
        </Grid.Row>

        {/*<Grid.Row cards={true}>
          <Grid.Col lg={2}>
            <Card>
              <Card.Header>
                <Card.Title> Vægtning af initiativer</Card.Title>
              </Card.Header>
              <Card.Body>
                 <C3Chart
                  style={{ height: "24rem" }}
                  data={{
                    columns:
                      [],
                     
                      

                    type: "pie", // default type of chart
                  }}
                  legend={{
                    show: false, //hide legend
                  }}
                  padding={{
                    bottom: 0,
                    top: 0,
                  }}
                />
              </Card.Body>
            </Card>
          </Grid.Col>
        </Grid.Row> */}

        {/*<Grid.Row>
          <Grid.Col width={6} sm={4} lg={4}>
            <StatCard name="Tilføj Nyt Modul"></StatCard>
          </Grid.Col>
        </Grid.Row>*/}
      </Page.Content>
    </SiteWrapper>
  );
}

export default Home;
