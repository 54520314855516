// @flow

import * as React from "react";

import { DefaultErrorPage } from "tabler-react";
import type { Props as DefaultErrorProps } from "tabler-react";

function Error401Page({
  title = "401",
  subtitle = "Uups... Du fandt en fejlside...",
  details = "Beklager, men denne side er udenfor din adgang...",
  action,
}: DefaultErrorProps): React.Node {
  return (
    <DefaultErrorPage
      title={title}
      subtitle={subtitle}
      details={details}
      action={action}
    />
  );
}

export default Error401Page;