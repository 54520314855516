import React from "react";
import { Card } from "tabler-react";
import "./index.css";

export function StatCard(props) {
  const name = props.name;
  const value = props.value;
  const color = props.color;
  const percentage = props.percentage;
  return (
    <>
      <div className="statcard">
        <Card {...props}>
          <Card.Status color={color} side />
          <h4>{percentage}</h4>
          <h1>{value}</h1> <p className="name">{name}</p>
        </Card>
      </div>
    </>
  );
}

export default StatCard;
