import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

import { Page, Grid, Card, Button } from "tabler-react";

import SiteWrapper from "../SiteWrapper.js";
import headers from "../../utils/headers";
import { Formik, Form } from "formik";
import { TextInput } from "../../components/FormikFields/TextInput";

import { useHistory } from "react-router-dom";

function CreateStrategyKPI() {
  //Find ID i URL
  const url = window.location.pathname.match(/^\/strategi\/(\d+)/);
  //var id = url
  const stratid = url[1];
  let history = useHistory();
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`https://api.beecon.dk/strategies/` + stratid, {
        headers: headers,
      })
      .then((res) => {
        const data = res.data.KPIS;

        setData(data);
      });
  }, [stratid]);

  const redirectAfterCreate = () => {
    history.push("/strategi/" + stratid + "/edit");
    window.location.reload(false);
  };

  const options = (
    <React.Fragment>
      <Button color="primary" type="submit">
        Gem
      </Button>
    </React.Fragment>
  );

  return (
    <SiteWrapper>
      <Formik
        initialValues={{
          form: {
            title: "",
            goalvalue: "",
            startvalue: "",
            currentvalue: "",
            importance: "",
          },
        }}
        onSubmit={(values) => {
          axios
            .put(
              `https://api.beecon.dk/strategies/` + stratid,
              {
                KPIS: [...data, values.form],
              },
              {
                headers: headers,
              }
            )
            .then((res) => {
              redirectAfterCreate();
            });
        }}
      >
        <Page.Content>
          <Form>
            <Page.Header options={options} />
            <Grid.Row cards={true}>
              <Card>
                <Card.Header>
                  <Card.Title>Tilføj KPI</Card.Title>
                </Card.Header>
                <Card.Body>
                  <TextInput name="form.title" label="KPI" />
                  <TextInput name="form.goalvalue" label="Mål" />
                  <TextInput name="form.startvalue" label="Start værdi" />
                  <TextInput name="form.currentvalue" label="Nuværende værdi" />
                  <TextInput name="form.importance" label="Vægt" />
                </Card.Body>
              </Card>
            </Grid.Row>
          </Form>
        </Page.Content>
      </Formik>
    </SiteWrapper>
  );
}

export default CreateStrategyKPI;
