import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Page, Grid, Card, Badge, Text, Button, Tag } from "tabler-react";

import auth from "../../utils/auth";
import headers from "../../utils/headers";
import DescriptionsStrat from "../../data/DescriptionsStrat";

import { useHistory } from "react-router-dom";

import SiteWrapper from "../SiteWrapper.js";
import LoadingCard from "../../components/LoadingCard";

const initiativeText = DescriptionsStrat.initiatives.text;

function StatusBadge(status) {
  if (status === "Aktiv") {
    return (
      <Badge color="success" className="mr-1">
        Aktiv
      </Badge>
    );
  } else if (status === "Afventer") {
    return (
      <Badge color="warning" className="mr-1">
        Afventer
      </Badge>
    );
  } else {
    return (
      <Badge color="danger" className="mr-1">
        Afsluttet
      </Badge>
    );
  }
}

function Initiatives() {
  let history = useHistory();
  const [initiatives, setInitiatives] = useState([]);

  const [loading, setLoading] = useState([]);

  const showIniative = (id) => {
    history.push("/initiativer/" + id + "/show");
  };

  const editClick = (id) => {
    history.push("/initiativer/" + id + "/edit");
  };

  const addClick = (id) => {
    history.push("/initiativer/tilfoej");
  };

  useEffect(() => {
    axios
      .get(
        `https://api.beecon.dk/initiatives?_where[strategy.business]=` +
          auth.get("userInfo").business.id,
        {
          //params: { businesses: auth.get("userInfo").business.id },
          headers: headers,
        }
      )
      .then((res) => {
        const data = res.data;

        setInitiatives(data);
        setLoading(false);
      });
  }, []);

  const options = (
    <React.Fragment>
      <Button color="primary" onClick={addClick}>
        Tilføj
      </Button>
    </React.Fragment>
  );

  return (
    <SiteWrapper>
      <Page.Content>
        <Page.Header title="Initiativer" options={options} />
        <Text className="mb-3 text-justify">{initiativeText}</Text>
        {!initiatives.length && !loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10vh",
            }}
          >
            <div>
              <div style={{ textAlign: "center" }}>
                <img
                  alt=""
                  src="https://api.beecon.dk/uploads/telescope_7b7933ca9a.png"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    height: "150px",
                    marginTop: "10vh",
                  }}
                />
                <h4>Det ser tomt ud her</h4>
                <p>Klik tilføj for at oprette dit første initiativ</p>
              </div>
            </div>
          </div>
        )}
        <Grid.Row cards={true}>
          {!loading ? (
            initiatives.map((initiative) => (
              <>
                <Card key={initiative.id}>
                  <Card.Status color="purple" side />
                  <Card.Header>
                    <Card.Title>
                      {StatusBadge(initiative.status)} {initiative.title}
                    </Card.Title>
                    <Card.Options>
                      <Tag>{initiative.responsible}</Tag>
                      <div style={{ paddingLeft: "8px" }}></div>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => showIniative(initiative.id)}
                      >
                        Vis
                      </Button>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => editClick(initiative.id)}
                      >
                        Rediger
                      </Button>
                      <Button
                        color="secondary"
                        size="sm"
                        className="ml-2"
                        onClick={() => {
                          axios
                            .delete(
                              `https://api.beecon.dk/initiatives/` +
                                initiative.id,
                              {
                                headers: headers,
                              }
                            )
                            .then((res) => {
                              window.location.reload(false);
                            });
                        }}
                      >
                        Slet
                      </Button>
                    </Card.Options>
                  </Card.Header>
                  <Card.Body>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: initiative.description,
                      }}
                    />
                  </Card.Body>
                  <Card.Footer>
                    {initiative.start_date} -{">"} {initiative.end_date}
                  </Card.Footer>
                </Card>
              </>
            ))
          ) : (
            <LoadingCard />
          )}
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>
  );
}

export default Initiatives;
