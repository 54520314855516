import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

import { Page, Grid, Card, Table, TabbedCard, Tab, Button } from "tabler-react";

import SiteWrapper from "../SiteWrapper.js";

import { Formik, Form } from "formik";
import { TextInput } from "../../components/FormikFields/TextInput";
import { RichTextInput } from "../../components/FormikFields/RichTextInput";
import { DateInput } from "../../components/FormikFields/DateInput";

import { useHistory } from "react-router-dom";
import headers from "../../utils/headers";

function EditStrategy() {
  let history = useHistory();
  //Find ID i URL
  const url = window.location.pathname.match(/^\/strategi\/(\d+)/);
  //var id = url
  const stratid = url[1];
  const [strategy, setStrategy] = useState({
    title: "",
    description: "",
    start_date: "",
    end_date: "",
  });

  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`https://api.beecon.dk/strategies/` + stratid, {
        headers: headers,
      })
      .then((res) => {
        const data = res.data;

        setStrategy(data);
        setData(data);
      });
  }, [stratid]);

  const addClick = () => {
    history.push("/strategi/" + stratid + "/edit/kpi/tilfoej");
  };

  const options = (
    <React.Fragment>
      <Button color="primary" type="submit">
        Gem
      </Button>
    </React.Fragment>
  );

  return (
    <SiteWrapper>
      {strategy.title ? (
        <Formik
          enableReinitialize
          initialValues={{
            form: {
              title: strategy.title,
              description: strategy.description,
              start_date: strategy.start_date,
              end_date: strategy.end_date,
            },
          }}
          onSubmit={(values) => {
            //setLoading(true)

            axios
              .put(`https://api.beecon.dk/strategies/` + stratid, values.form, {
                headers: headers,
              })
              .then((res) => {
                setTimeout(() => {
                  history.push("/strategi/");
                }, 1000);
              });
          }}
        >
          <Page.Content>
            <Form>
              <Page.Header options={options} title="Rediger" />
              <Grid.Row cards={true}>
                <TabbedCard initialTab="Generelt">
                  <Tab title="Generelt">
                    <Card.Body>
                      <TextInput
                        name="form.title"
                        label="Titel på din strategi"
                      />
                      <RichTextInput
                        name="form.description"
                        onBlur={(e) => {
                          return;
                        }}
                        label="Beskrivelse af strategien"
                      />
                      <DateInput
                        name="form.start_date"
                        label="Start dato for din strategi"
                      />
                      <DateInput
                        name="form.end_date"
                        label="Slut dato for din strategi"
                      />
                    </Card.Body>
                  </Tab>
                  <Tab title="KPIs">
                    <Button.List align="right">
                      <Button color="primary" onClick={addClick}>
                        Tilføj KPI
                      </Button>
                    </Button.List>

                    <Table>
                      <Table.Header>
                        <Table.ColHeader>KPI</Table.ColHeader>
                        <Table.ColHeader>Mål</Table.ColHeader>
                        <Table.ColHeader>Start værdi</Table.ColHeader>
                        <Table.ColHeader>Nuværende værdi</Table.ColHeader>
                        <Table.ColHeader>Vægt</Table.ColHeader>
                      </Table.Header>
                      <Table.Body>
                        {(data.KPIS || []).map((kpi) => (
                          <Table.Row>
                            <Table.Col>{kpi.title}</Table.Col>
                            <Table.Col>{kpi.goalvalue}</Table.Col>
                            <Table.Col>{kpi.startvalue}</Table.Col>
                            <Table.Col>{kpi.currentvalue}</Table.Col>
                            <Table.Col>{kpi.importance}</Table.Col>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </Tab>
                </TabbedCard>
              </Grid.Row>
              <div style={{ float: "right", paddingBottom: "2rem" }}>
                {options}
              </div>
            </Form>
          </Page.Content>
        </Formik>
      ) : (
        "Loading..."
      )}
    </SiteWrapper>
  );
}

export default EditStrategy;
