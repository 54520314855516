import * as React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import {
  Page,
  Grid,
  Card,
  Table,
  Button,
  StatsCard,
  Badge,
  TabbedCard,
  Tab,
} from "tabler-react";
import SiteWrapper from "../SiteWrapper.js";
import { useHistory } from "react-router-dom";
import headers from "../../utils/headers";

function AdminPanel() {
  let history = useHistory();

  const [users, setUsers] = useState([]);
  const [businesses, setBusinesses] = useState([]);

  useEffect(() => {
    axios
      .get(`https://api.beecon.dk/users`, {
        headers: headers,
      })
      .then((res) => {
        const data = res.data;
        setUsers(data);
      });
    axios
      .get(`https://api.beecon.dk/businesses`, {
        headers: headers,
      })
      .then((res) => {
        const data = res.data;
        setBusinesses(data);
      });
  }, []);

  const addClick = () => {
    history.push("/adminpanel/virksomhed/tilfoej");
  };

  return (
    <SiteWrapper>
      <Page.Content title="Admin Panel">
        <Grid.Row cards={true}>
          <TabbedCard initialTab="Statistik">
            <Tab title="Statistik">
              <Grid.Row cards alignItems="center">
                <Grid.Col>
                  <StatsCard
                    layout={1}
                    movement={businesses.length}
                    total={businesses.length}
                    label="Virksomheder"
                  />
                </Grid.Col>
                <Grid.Col>
                  <StatsCard
                    layout={1}
                    movement={users.length}
                    total={users.length}
                    label="Brugere"
                  />
                </Grid.Col>
                <Grid.Col>
                  <StatsCard layout={1} movement={0} total={0} label="#" />
                </Grid.Col>
              </Grid.Row>
            </Tab>
            <Tab title="Virksomheder">
              <Button.List align="right">
                <Button color="primary" onClick={addClick}>
                  Tilføj virksomhed
                </Button>
              </Button.List>

              <Table>
                <Table.Header>
                  <Table.ColHeader>ID</Table.ColHeader>
                  <Table.ColHeader>Virksomhed</Table.ColHeader>
                  <Table.ColHeader>Tilknyttede brugere</Table.ColHeader>
                </Table.Header>
                <Table.Body>
                  {businesses.map((business) => (
                    <Table.Row key={business.id}>
                      <Table.Col>{business.id}</Table.Col>
                      <Table.Col>{business.title}</Table.Col>
                      <Table.Col>{business.users.length}</Table.Col>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Tab>

            <Tab title="Brugere">
              <Button.List align="right">
                <Button
                  color="primary"
                  onClick={() => history.push("/adminpanel/bruger/tilfoej")}
                >
                  Tilføj bruger
                </Button>
              </Button.List>
              <Table>
                <Table.Header>
                  <Table.ColHeader>ID</Table.ColHeader>
                  <Table.ColHeader>Fulde navn</Table.ColHeader>
                  <Table.ColHeader>Brugernavn</Table.ColHeader>
                  <Table.ColHeader>E-mailadresse</Table.ColHeader>
                  <Table.ColHeader>Rolle</Table.ColHeader>
                  <Table.ColHeader>Virksomhed</Table.ColHeader>
                  <Table.ColHeader>Action</Table.ColHeader>
                </Table.Header>
                <Table.Body>
                  {users.map((user) => (
                    <Table.Row key={user.id}>
                      <Table.Col>{user.id}</Table.Col>
                      <Table.Col>
                        {user.firstname} {user.lastname}
                      </Table.Col>
                      <Table.Col>{user.username}</Table.Col>

                      <Table.Col>{user.email}</Table.Col>
                      <Table.Col>
                        {user.admin ? (
                          <Badge color="success" className="mr-1">
                            Admin
                          </Badge>
                        ) : (
                          <Badge color="primary" className="mr-1">
                            Bruger
                          </Badge>
                        )}
                      </Table.Col>
                      <Table.Col>{user.business.title}</Table.Col>
                      <Table.Col>
                        <Button color="primary" disabled>
                          Rediger
                        </Button>
                      </Table.Col>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Tab>
          </TabbedCard>
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>
  );
}

export default AdminPanel;
