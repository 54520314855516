import * as React from "react";
import axios from "axios";
import { Page, Grid, Card, Button } from "tabler-react";

import SiteWrapper from "../SiteWrapper.js";

import { Formik, Form } from "formik";
import { TextInput } from "../../components/FormikFields/TextInput";
import headers from "../../utils/headers";

import { useHistory } from "react-router-dom";

function CreateBusiness() {
  let history = useHistory();

  const redirectAfterCreate = () => {
    history.push("/adminpanel/");
    window.location.reload(false);
  };

  const options = (
    <React.Fragment>
      <Button color="primary" type="submit">
        Gem
      </Button>
    </React.Fragment>
  );

  return (
    <SiteWrapper>
      <Formik
        initialValues={{
          form: {
            title: "",
          },
        }}
        onSubmit={(values) => {
          axios
            .post(`https://api.beecon.dk/businesses/`, values.form, {
              headers: headers,
            })
            .then((res) => {
              redirectAfterCreate();
            });
        }}
      >
        <Page.Content>
          <Form>
            <Page.Header options={options} />
            <Grid.Row cards={true}>
              <Card>
                <Card.Header>
                  <Card.Title>Tilføj virksomhed</Card.Title>
                </Card.Header>
                <Card.Body>
                  <TextInput name="form.title" label="Virksomhed" />
                </Card.Body>
              </Card>
            </Grid.Row>
          </Form>
        </Page.Content>
      </Formik>
    </SiteWrapper>
  );
}

export default CreateBusiness;
