import * as React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Page, Grid, Card, Text, Button, Tag, Badge, Icon } from "tabler-react";

import auth from "../../utils/auth";

import DescriptionsStrat from "../../data/DescriptionsStrat";

import { useHistory } from "react-router-dom";
import headers from "../../utils/headers";

import SiteWrapper from "../SiteWrapper.js";
import useApiRequest from "../../utils/store";
import { FlexboxGrid } from "rsuite";
import LoadingCard from "../../components/LoadingCard";

import FinishedActivities from "./components/FinishedActivities";
const activitiesText = DescriptionsStrat.activities.text;

function StatusBadge(status, title) {
  if (status === "Aktiv") {
    return (
      <>
        <Badge color="success" className="mr-1">
          Aktiv
        </Badge>

        {title}
      </>
    );
  } else if (status === "Afventer") {
    return (
      <>
        <Badge color="warning" className="mr-1">
          Afventer
        </Badge>
        {title}
      </>
    );
  } else {
    return (
      <>
        <Badge color="danger" className="mr-1">
          Afsluttet
        </Badge>
        {title}
      </>
    );
  }
}

function Activities() {
  let history = useHistory();
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const { data, error, isLoaded } = useApiRequest(
    `  https://api.beecon.dk/activities?_where[effort.initiative.strategy.business]=` +
      auth.get("userInfo").business.id
  );

  const editClick = (id) => {
    history.push("/aktiviteter/" + id + "/edit");
  };

  const addClick = (id) => {
    history.push("/aktiviteter/tilfoej");
  };

  const statuschoice = [
    { value: "Aktiv", label: "Aktiv" },
    { value: "Afventer", label: "Afventer" },
    { value: "Afsluttet", label: "Afsluttet" },
  ];

  useEffect(() => {
    axios
      .get(
        `https://api.beecon.dk/activities?_where[effort.initiative.strategy.business]=` +
          auth.get("userInfo").business.id +
          "&_where[finished]=false",
        {
          headers: headers,
        }
      )
      .then((res) => {
        const data = res.data;

        setActivities(data);
        setLoading(false);
      });
  }, []);

  const options = (
    <React.Fragment>
      <Button color="primary" onClick={addClick}>
        Tilføj
      </Button>
    </React.Fragment>
  );
  return (
    <SiteWrapper>
      <Page.Content>
        <Page.Header title="Aktiviteter" options={options} />
        <Text className="mb-3 text-justify">{activitiesText}</Text>
        {!activities.length && !loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10vh",
            }}
          >
            <div>
              <div style={{ textAlign: "center" }}>
                <img
                  alt=""
                  src="https://api.beecon.dk/uploads/telescope_7b7933ca9a.png"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    height: "150px",
                    marginTop: "10vh",
                  }}
                />
                <h4>Det ser tomt ud her</h4>
                <p>Klik tilføj for at oprette din første aktivitet</p>
              </div>
            </div>
          </div>
        )}
        <Grid.Row cards={true}>
          <h4 style={{ paddingTop: "25px" }}>Igangværende aktiviteter</h4>

          {!loading ? (
            activities.map((activity) => (
              <>
                <Card
                  key={activity.id}
                  title={StatusBadge(activity.status, activity.title)}
                  isCollapsible
                  isCollapsed={true}
                  options={
                    <>
                      <Tag>{activity.responsible}</Tag>
                      <div style={{ paddingLeft: "8px" }}></div>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => editClick(activity.id)}
                      >
                        Rediger
                      </Button>
                      <Button
                        color="success"
                        size="sm"
                        className="ml-2"
                        onClick={(e) =>
                          axios
                            .put(
                              `https://api.beecon.dk/activities/` + activity.id,
                              {
                                finished: true,
                                headers: headers,
                              }
                            )
                            .then((res) => {
                              window.location.reload(false);
                            })
                        }
                      >
                        Færdig
                      </Button>
                    </>
                  }
                >
                  <Card.Status color="teal" side />
                  {/*<Card.Header>
                    <Card.Title>
                      {StatusBadge(activity.status)} {activity.title}
                    </Card.Title>
                    <Card.Options>
                      <Tag>{activity.responsible}</Tag>
                      <div style={{ paddingLeft: "8px" }}></div>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => editClick(activity.id)}
                      >
                        Rediger
                      </Button>
                      <Button
                        color="success"
                        size="sm"
                        className="ml-2"
                        onClick={(e) =>
                          axios
                            .put(
                              `https://api.beecon.dk/activities/` + activity.id,
                              {
                                finished: true,
                                headers: headers,
                              }
                            )
                            .then((res) => {
                              window.location.reload(false);
                            })
                        }
                      >
                        Færdig
                      </Button>
                      {/*<Button
                        color="success"
                        size="sm"
                        className="ml-2"
                        onClick={(e) =>
                          window.confirm(
                            "Are you sure you wish to clear the page?"
                          ) &&
                          axios
                            .delete(
                              `https://api.beecon.dk/activities/` + activity.id,
                              {
                                headers: headers,
                              }
                            )
                            .then((res) => {
                              window.location.reload(false);
                            })
                        }
                      >
                        Færdig
                      </Button>
                    </Card.Options>
                  </Card.Header> */}
                  <Card.Body>
                    <div
                      dangerouslySetInnerHTML={{ __html: activity.description }}
                    />
                  </Card.Body>

                  <Card.Footer>
                    <strong>{activity.effort.title}</strong>
                    {" | "}
                    {activity.start_date} -{">"} {activity.end_date}
                  </Card.Footer>
                </Card>
              </>
            ))
          ) : (
            <LoadingCard />
          )}
          <FinishedActivities />
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>
  );
}

export default Activities;
