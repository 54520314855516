import React from 'react';
import { useField } from 'formik';
import { Form } from 'tabler-react';

export function DateInput(props) {
  // this will return field props for an <input />
  // eslint-disable-line no-unused-vars
  const [field, meta, helpers] = useField(props.name);
  return (
    <>
      <Form.Group {...props}>
        <Form.MaskedInput
          name="start_date"
          placeholder="åååå-mm- dd"
          mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
          {...field}
        />
      </Form.Group>
      {meta.error && meta.touched && <div>{meta.error}</div>}
    </>
  );
}
