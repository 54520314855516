import React from 'react';
import { useField } from 'formik';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export function RichTextInput(props) {
  // This isn't an input, so instead of using the values in 'field' directly,
  // we'll use 'meta' and 'helpers'.
  const [field, meta, helpers] = useField(props.name);

  const { value } = meta;
  const { setValue } = helpers;

  return (
    <>
      <ReactQuill
        {...field}
        {...props}
        value={value}
        onChange={setValue}
        initialValue=""
      />
      {meta.error && meta.touched && <div>{meta.error}</div>}
    </>
  );
}
