import React from "react";

import {
  Container,
  Grid,
  Card,
  Button,
  Form,
  Avatar,
  Profile,
  Comment,
} from "tabler-react";

import auth from "../../utils/auth";
import AvatarImg from "../../assets/Avatar.svg";

import SiteWrapper from "../SiteWrapper";

function ProfilePage() {
  const UserEmail = auth.get("userInfo").email;
  const UserFullname = auth.get("userInfo").firstname
    ? auth.get("userInfo").firstname.concat(auth.get("userInfo").lastname)
    : "Bruger";
  return (
    <SiteWrapper>
      <div className="my-3 my-md-5">
        <Container>
          <Grid.Row>
            <Grid.Col lg={4}>
              <Card>
                <Card.Header>
                  <Card.Title>Min Profil</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form>
                    <Grid.Row>
                      <Grid.Col auto>
                        <Avatar size="xl" imageURL={AvatarImg} />
                      </Grid.Col>
                      <Grid.Col>
                        <Form.Group>
                          <Form.Label>Email-Address</Form.Label>
                          <Form.Input disabled placeholder={UserEmail} />
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                    <Form.Group>
                      <Form.Label>Bio</Form.Label>
                      <Form.Textarea rows={5} placeholder="Desc" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Email-Address</Form.Label>
                      <Form.Input placeholder="your-email@domain.com" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Password</Form.Label>
                      <Form.Input type="password" value="Password" />
                    </Form.Group>
                    <Form.Footer>
                      <Button color="primary" block>
                        Save
                      </Button>
                    </Form.Footer>
                  </Form>
                </Card.Body>
              </Card>
            </Grid.Col>
            <Grid.Col lg={8}>
              <Form className="card">
                <Card.Body>
                  <Card.Title>Indstillinger</Card.Title>
                  <Grid.Row>
                    <Grid.Col md={6}>
                      <Form.Group>
                        <Form.Label>Virksomhed</Form.Label>
                        <Form.Input
                          type="text"
                          disabled
                          placeholder="Company"
                          value={auth.get("userInfo").business.title}
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col sm={6} md={6}>
                      <Form.Group>
                        <Form.Label>Position</Form.Label>
                        <Form.Input
                          type="text"
                          placeholder="Position"
                          value=""
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col sm={6} md={6}>
                      <Form.Group>
                        <Form.Label>Brugernavn</Form.Label>
                        <Form.Input
                          type="text"
                          placeholder="Brugernavn"
                          disabled
                          value={auth.get("userInfo").username}
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col sm={6} md={6}>
                      <Form.Group>
                        <Form.Label>Mailadresse</Form.Label>
                        <Form.Input
                          type="email"
                          disabled
                          placeholder="Email"
                          value={UserEmail}
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col sm={6} md={6}>
                      <Form.Group>
                        <Form.Label>Fornavn</Form.Label>
                        <Form.Input
                          type="text"
                          placeholder="Fornavn"
                          value={auth.get("userInfo").firstname}
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col sm={6} md={6}>
                      <Form.Group>
                        <Form.Label>Efternavn</Form.Label>
                        <Form.Input
                          type="text"
                          placeholder="Efternavn"
                          value={auth.get("userInfo").lastname}
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col sm={6} md={4}>
                      <Form.Group>
                        <Form.Label>By</Form.Label>
                        <Form.Input
                          type="text"
                          placeholder="By"
                          disabled
                          value={auth.get("userInfo").city}
                        />
                      </Form.Group>
                    </Grid.Col>

                    <Grid.Col md={5}>
                      <Form.Group>
                        <Form.Label>Land</Form.Label>
                        <Form.Select>
                          <option>Danmark</option>
                        </Form.Select>
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>
                </Card.Body>
                <Card.Footer className="text-right">
                  <Button type="submit" color="primary">
                    Gem ændringer
                  </Button>
                </Card.Footer>
              </Form>
            </Grid.Col>
          </Grid.Row>
        </Container>
      </div>
    </SiteWrapper>
  );
}

export default ProfilePage;
