// @flow

import * as React from "react";
import { NavLink, withRouter } from "react-router-dom";

import {
  Site,
  Nav,
  Grid,
  List,
  Button,
  RouterContextProvider,
} from "tabler-react";

import auth from "../utils/auth";
import AvatarImg from "../assets/Avatar.svg";

// import type { NotificationProps } from "tabler-react";

// type Props = {|
//   +children: React.Node,
// |};

// type State = {|
//   notificationsObjects: Array<NotificationProps>,
// |};

// type subNavItem = {|
//   +value: string,
//   +to?: string,
//   +icon?: string,
//   +LinkComponent?: React.ElementType,
//   +useExact?: boolean,
// |};

// type navItem = {|
//   +value: string,
//   +to?: string,
//   +icon?: string,
//   +active?: boolean,
//   +LinkComponent?: React.ElementType,
//   +subItems?: Array<subNavItem>,
//   +useExact?: boolean,
// |};

const navBarItems: Array<navItem> = [
  {
    value: "Hjem",
    to: "/",
    icon: "home",
    LinkComponent: withRouter(NavLink),
    useExact: true,
  },
  {
    value: "Strategi",
    to: "/strategi",
    icon: "box",
    LinkComponent: withRouter(NavLink),
  },
  {
    value: "Initiativer",
    to: "/initiativer",
    icon: "calendar",
    LinkComponent: withRouter(NavLink),
  },
  {
    value: "Indsatser",
    to: "/indsatser",
    icon: "file",
    LinkComponent: withRouter(NavLink),
  },
  {
    value: "Aktiviteter",
    to: "/aktiviteter",
    icon: "check-square",
    LinkComponent: withRouter(NavLink),
  },

  /*{
    value: "Dokumenter",
    to: "/dokumenter",
    icon: "file-text",
    LinkComponent: withRouter(NavLink),
  },*/
];

const accountDropdownProps = {
  avatarURL: AvatarImg,
  name: auth.get("userInfo")
    ? auth.get("userInfo").firstname + " " + auth.get("userInfo").lastname
    : "",
  description: auth.get("userInfo") ? auth.get("userInfo").business.title : "",
  options: [
    { icon: "user", value: "Profil", to: "/profil" },
    { isDivider: true },
    { icon: "log-out", value: "Log af", to: "/logout" },
  ],
};

class SiteWrapper extends React.Component<Props, State> {
  state = {
    notificationsObjects: [
      {
        unread: true,
        avatarURL: AvatarImg,
        message: (
          <React.Fragment>
            <strong>Nathan</strong> pushed new commit: Fix page load performance
            issue.
          </React.Fragment>
        ),
        time: "10 minutes ago",
      },
      {
        unread: true,
        avatarURL: AvatarImg,
        message: (
          <React.Fragment>
            <strong>Alice</strong> started new task: Tabler UI design.
          </React.Fragment>
        ),
        time: "1 hour ago",
      },
      {
        unread: false,
        avatarURL: AvatarImg,
        message: (
          <React.Fragment>
            <strong>Rose</strong> deployed new version of NodeJS REST Api // V3
          </React.Fragment>
        ),
        time: "2 hours ago",
      },
    ],
  };
  render(): React.Node {
    const notificationsObjects = this.state.notificationsObjects || [];
    const unreadCount = this.state.notificationsObjects.reduce(
      (a, v) => a || v.unread,
      false
    );
    return (
      <Site.Wrapper
        headerProps={{
          href: "/",
          alt: "Beecon",
          imageURL: "/assets/BeeconLogoB.png",
          navItems: (
            <Nav.Item type="div" className="d-none d-md-flex">
              {auth.get("userInfo").admin && (
                <Button
                  outline
                  size="sm"
                  href="adminpanel"
                  RootComponent="a"
                  color="primary"
                  className="mr-2"
                >
                  Adminpanel
                </Button>
              )}
            </Nav.Item>
          ),
          /*notificationsTray: {
            notificationsObjects,
            markAllAsRead: () =>
              this.setState(
                () => ({
                  notificationsObjects: this.state.notificationsObjects.map(
                    (v) => ({ ...v, unread: false })
                  ),
                }),
                () =>
                  setTimeout(
                    () =>
                      this.setState({
                        notificationsObjects: this.state.notificationsObjects.map(
                          (v) => ({ ...v, unread: true })
                        ),
                      }),
                    5000
                  )
              ),
            unread: unreadCount,
          },*/
          accountDropdown: accountDropdownProps,
        }}
        navProps={{ itemsObjects: navBarItems }}
        routerContextComponentType={withRouter(RouterContextProvider)}
        footerProps={{
          links: [
            /*<a href="#">First Link</a>,
            <a href="#">Second Link</a>,
            <a href="#">Third Link</a>,
            <a href="#">Fourth Link</a>,
            <a href="#">Five Link</a>,
            <a href="#">Sixth Link</a>,
            <a href="#">Seventh Link</a>,
            <a href="#">Eigth Link</a>,*/
          ],
          note: (
            <React.Fragment>
              <p style={{ textAlign: "right" }}>
                Beecon - Strategiværktøjet til at klarlægge, planlægge og
                eksekvere på din virksomheds strategi.
              </p>
            </React.Fragment>
          ),
          copyright: (
            <React.Fragment>
              Copyright © 2020
              <a href="/"> Beecon.</a> Alle Rettigheder Forbeholdes.
            </React.Fragment>
          ),
          nav: (
            <React.Fragment>
              <Grid.Col auto={true}>
                <List className="list-inline list-inline-dots mb-0"></List>
              </Grid.Col>
              <Grid.Col auto={true}>
                <Button size="sm" outline color="primary" RootComponent="a">
                  Support
                </Button>
              </Grid.Col>
            </React.Fragment>
          ),
        }}
      >
        {this.props.children}
      </Site.Wrapper>
    );
  }
}

export default SiteWrapper;
