import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";

import { Page, Grid, Card, Alert, colors, Button, Header } from "tabler-react";
import C3Chart from "react-c3js";

import auth from "../../utils/auth";
import headers from "../../utils/headers";

function KPI_Tracker() {
  const [activeStrategy, setActiveStrategy] = useState("");
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);

  useEffect(() => {
    axios
      .get(`https://api.beecon.dk/strategies`, {
        params: { business: auth.get("userInfo").business.id },
        headers: headers,
      })
      .then((res) => {
        const data = res.data;
        if (data[0]) {
          setActiveStrategy(data[0]);
          setStartDate(new Date(data[0].start_date));
          setEndDate(new Date(data[0].end_date));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  let length = [];
  let currentValue = [];
  let startValue = [];
  let goalValue = [];
  let weightValue = [];
  let ScoreSingleKPI = [];
  let sumKPI = 0;
  let sumWeights = 0;
  let scoreKPI = 0;
  let testData = [0, 4, 27, 27, 34, 40, 60, 62, 80, 95, 100];

  for (let i = 0; i < testData.length + 1; i++) {
    length[i] = i + 1;
  }

  if (activeStrategy.KPIS) {
    for (let i = 0; i < activeStrategy.KPIS.length; i++) {
      currentValue[i] = activeStrategy.KPIS[i].currentvalue;
      startValue[i] = activeStrategy.KPIS[i].startvalue;
      goalValue[i] = activeStrategy.KPIS[i].goalvalue;
      weightValue[i] = activeStrategy.KPIS[i].importance;
      ScoreSingleKPI[i] =
        (currentValue[i] - startValue[i]) / (goalValue[i] - startValue[i]);

      sumWeights += weightValue[i];
      sumKPI += ScoreSingleKPI[i] * weightValue[i];
      scoreKPI = Math.floor((sumKPI / sumWeights) * 100);
    }
  }

  const diffDate = Math.abs(
    Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24 * 30.5))
  );

  const date = new Date();
  let currentDate = Math.abs(
    Math.ceil((date - startDate) / (1000 * 60 * 60 * 24 * 30.5))
  );

  if (currentDate > diffDate) {
    currentDate = diffDate - 1;
  }

  // const trendLine = ["Fremgang"].concat(
  //   Array.apply(0, Array(diffDate)).map(function (_, b) {
  //     return Math.floor((100 / (diffDate - 1)) * b);
  //   })
  // );

  // const kpiStatus = ["kpiStatus", 0].concat(
  //   Array.from({ length: currentDate }, () => scoreKPI)
  // );

  const trendLine = ["Fremgang"].concat(
    Array.apply(0, Array(diffDate)).map(function (_, b) {
      return Math.floor((100 / (diffDate - 1)) * b);
    })
  );

  const kpiStatus = ["kpiStatus", 0].concat(testData);

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          {activeStrategy.title
            ? "Fremgang: " + activeStrategy.title
            : "Fremgang på strategien"}
        </Card.Title>
      </Card.Header>

      <C3Chart
        style={{ height: "80%" }}
        data={{
          columns: [
            // each columns data
            kpiStatus,
            trendLine,
          ],
          type: "area", // default type of chart
          // groups: [["data1", "trendLine"]],
          colors: {
            kpiStatus: "#a55eea",
            trendLine: "#a55eea",
          },
          names: {
            // name of each serie
            kpiStatus: "Status på KPIs",
            trendLine: "Fremgang",
          },
        }}
        axis={{
          y: {
            padding: {
              top: 110,
            },
            show: false,
            tick: {
              outer: false,
            },
          },
          x: {
            padding: {
              left: 0,
              right: 0,
            },
            show: true,
            type: "category",
            categories: length,
          },
        }}
        legend={{
          position: "inset",
          padding: 0,
          inset: {
            anchor: "top-left",
            x: 20,
            y: 8,
            step: 10,
          },
        }}
        tooltip={{
          format: {
            title: function (x) {
              return "";
            },
          },
        }}
        padding={{
          bottom: 0,
          left: -1,
          right: -1,
        }}
        point={{
          show: false,
        }}
      />
    </Card>
  );
}

export default KPI_Tracker;
