// @flow

import * as React from "react";

import { DefaultErrorPage } from "tabler-react";
import type { Props as DefaultErrorProps } from "tabler-react";

function Error503Page({
  title = "503",
  subtitle = "Uups... Du fandt en fejlside...",
  details = "Denne side er midlertidigt utilgængelig...",
  action,
}: DefaultErrorProps): React.Node {
  return (
    <DefaultErrorPage
      title={title}
      subtitle={subtitle}
      details={details}
      action={action}
    />
  );
}

export default Error503Page;