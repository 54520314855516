import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";

import { Page, Grid, Card, Button } from "tabler-react";

import auth from "../../utils/auth";

import SiteWrapper from "../SiteWrapper.js";
import { SelectField } from "../../components/FormikFields/SelectField";

import { Formik, Form, Field } from "formik";
import { TextInput } from "../../components/FormikFields/TextInput";
import { RichTextInput } from "../../components/FormikFields/RichTextInput";
import { DateInput } from "../../components/FormikFields/DateInput";

import headers from "../../utils/headers";
import { useHistory } from "react-router-dom";

function CreateEffort() {
  let history = useHistory();
  const [initiatives, setInitiatives] = useState([]);

  const redirectAfterCreate = () => {
    history.push("/indsatser/");
    window.location.reload(false);
  };

  const statuschoice = [
    { value: "Aktiv", label: "Aktiv" },
    { value: "Afventer", label: "Afventer" },
    { value: "Afsluttet", label: "Afsluttet" },
  ];

  useEffect(() => {
    axios
      .get(
        `https://api.beecon.dk/initiatives?_where[strategy.business]=` +
          auth.get("userInfo").business.id,
        {
          headers: headers,
        }
      )
      .then((res) => {
        const data = res.data;

        setInitiatives(data);
      });
  });

  const options = (
    <React.Fragment>
      <Button color="primary" type="submit">
        Gem
      </Button>
    </React.Fragment>
  );

  return (
    <SiteWrapper>
      <Formik
        initialValues={{
          form: {
            title: "",
            description: "",
            start_date: "",
            end_date: "",
            responsible: "",
            initiative: "",
            status: "",
          },
        }}
        onSubmit={(values) => {
          axios
            .post(`https://api.beecon.dk/efforts/`, values.form, {
              headers: headers,
            })
            .then((res) => {
              redirectAfterCreate();
            });
        }}
      >
        <Page.Content>
          <Form>
            <Page.Header options={options} />
            <Grid.Row cards={true}>
              <Card>
                <Card.Header>
                  <Card.Title>Rediger</Card.Title>
                </Card.Header>
                <Card.Body>
                  <p>Status</p>
                  <Field
                    name="form.status"
                    component={SelectField}
                    options={statuschoice}
                  />

                  <TextInput name="form.title" label="Titel på din indsats" />
                  <RichTextInput
                    name="form.description"
                    onBlur={(e) => {
                      return;
                    }}
                    label="Beskrivelse af indsatsen"
                  />
                  <DateInput
                    name="form.start_date"
                    label="Start dato for din indsats"
                  />
                  <DateInput
                    name="form.end_date"
                    label="Slut dato for din indsats"
                  />
                  <TextInput name="form.responsible" label="Ansvarlige" />
                  <p>
                    <strong>Hvilket initiativ tilhører denne indsats?</strong>
                  </p>
                  <Field
                    name="form.initiative"
                    component={SelectField}
                    options={initiatives.map((initiative) => ({
                      value: initiative.id,
                      label: initiative.title,
                    }))}
                  />
                </Card.Body>
              </Card>
            </Grid.Row>
          </Form>
        </Page.Content>
      </Formik>
    </SiteWrapper>
  );
}

export default CreateEffort;
