import React from 'react';
import { useField } from 'formik';
import { Form } from 'tabler-react';

export function TextInput(props) {
  // this will return field props for an <input />
  // eslint-disable-line no-unused-vars
  const [field, meta, helpers] = useField(props.name);
  return (
    <>
      <Form.Input {...field} {...props} />
      {meta.error && meta.touched && <div>{meta.error}</div>}
    </>
  );
}
