// @flow

import * as React from "react";
import { Redirect } from "react-router-dom";
import auth from "../utils/auth";

const logOut = () => {
  auth.clearAppStorage();
  // window.location.reload(false);
    return (
            <Redirect
          to={{
            pathname: '/auth/login',
          }}/>
    )
  }

export default logOut;