import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import auth from '../auth';

//Kun brugere med admin == true kan tilgå AdminRoutes
const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      auth.get('userInfo').admin ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default AdminRoute;
