import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Page, Grid, Card, Table, TabbedCard, Tab, Button } from "tabler-react";

import SiteWrapper from "../SiteWrapper.js";
import auth from "../../utils/auth";

import { Formik, Form, Field } from "formik";
import { TextInput } from "../../components/FormikFields/TextInput";
import { RichTextInput } from "../../components/FormikFields/RichTextInput";
import { DateInput } from "../../components/FormikFields/DateInput";
import { SelectField } from "../../components/FormikFields/SelectField";
import { useHistory } from "react-router-dom";
import headers from "../../utils/headers";

function EditInitiative() {
  let history = useHistory();
  const [strategies, setStrategies] = useState([]);

  //Find ID i URL
  const url = window.location.pathname.match(/^\/initiativer\/(\d+)/);
  //var id = url
  const initid = url[1];
  const [initiative, setInitiative] = useState({
    title: "",
    description: "",
    start_date: "",
    responsible: "",
    end_date: "",
    strategy: "",
    status: "",
  });

  const statuschoice = [
    { value: "Aktiv", label: "Aktiv" },
    { value: "Afventer", label: "Afventer" },
    { value: "Afsluttet", label: "Afsluttet" },
  ];

  useEffect(() => {
    axios
      .get(
        `https://api.beecon.dk/strategies?_where[business]=` +
          auth.get("userInfo").business.id,
        {
          headers: headers,
        }
      )
      .then((res) => {
        const data = res.data;

        setStrategies(data);
      });
  });

  const addClick = () => {
    history.push("/initiativer/" + initid + "/edit/kpi/tilfoej");
  };
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`https://api.beecon.dk/initiatives/` + initid, {
        headers: headers,
      })
      .then((res) => {
        const data = res.data;

        setInitiative(data);
        setData(data);
      });
  }, [initid]);

  console.log(initiative.strategy);

  const options = (
    <React.Fragment>
      <Button color="primary" type="submit">
        Gem
      </Button>
    </React.Fragment>
  );

  return (
    <SiteWrapper>
      {initiative.title ? (
        <Formik
          enableReinitialize
          initialValues={{
            form: {
              title: initiative.title,
              description: initiative.description,
              responsible: initiative.responsible,
              start_date: initiative.start_date,
              end_date: initiative.end_date,
              strategy: initiative.strategy.id,
              status: initiative.status,
            },
          }}
          onSubmit={(values) => {
            axios
              .put(`https://api.beecon.dk/initiatives/` + initid, values.form, {
                headers: headers,
              })
              .then((res) => {
                setTimeout(() => {
                  history.push("/initiativer/");
                }, 1000);
              });
          }}
        >
          <Page.Content>
            <Form>
              <Page.Header options={options} />
              <TabbedCard initialTab="Generelt">
                <Tab title="Generelt">
                  <Grid.Row cards={true}>
                    <Card>
                      <Card.Header>
                        <Card.Title>Rediger</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <p>Status</p>
                        <Field
                          name="form.status"
                          component={SelectField}
                          options={statuschoice}
                        />

                        <TextInput
                          name="form.title"
                          label="Titel på dit initiativ"
                        />
                        <RichTextInput
                          name="form.description"
                          onBlur={(e) => {
                            return;
                          }}
                          label="Beskrivelse af initiativet"
                        />

                        <DateInput
                          name="form.start_date"
                          label="Start dato for dit initiativ"
                        />
                        <DateInput
                          name="form.end_date"
                          label="Slut dato for dit initiativ"
                        />
                        <TextInput name="form.responsible" label="Ansvarlige" />
                        <p>Hvilken strategi tilhører dette initiativ?</p>
                        <Field
                          name="form.strategy"
                          component={SelectField}
                          options={strategies.map((strategy) => ({
                            value: strategy.id,
                            label: strategy.title,
                          }))}
                        />
                      </Card.Body>
                    </Card>
                  </Grid.Row>
                </Tab>
                <Tab title="KPIs">
                  <Button.List align="right">
                    <Button color="primary" onClick={addClick}>
                      Tilføj KPI
                    </Button>
                  </Button.List>

                  <Table>
                    <Table.Header>
                      <Table.ColHeader>KPI</Table.ColHeader>
                      <Table.ColHeader>Mål</Table.ColHeader>
                      <Table.ColHeader>Start værdi</Table.ColHeader>
                      <Table.ColHeader>Nuværende værdi</Table.ColHeader>
                      <Table.ColHeader>Vægt</Table.ColHeader>
                    </Table.Header>
                    <Table.Body>
                      {(data.KPIS || []).map((kpi) => (
                        <Table.Row>
                          <Table.Col>{kpi.title}</Table.Col>
                          <Table.Col>{kpi.goalvalue}</Table.Col>
                          <Table.Col>{kpi.startvalue}</Table.Col>
                          <Table.Col>{kpi.currentvalue}</Table.Col>
                          <Table.Col>{kpi.importance}</Table.Col>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Tab>
              </TabbedCard>
            </Form>
          </Page.Content>
        </Formik>
      ) : (
        "Loading..."
      )}
    </SiteWrapper>
  );
}

export default EditInitiative;
