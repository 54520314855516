import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

import { Page, Grid, Card, Table, TabbedCard, Tab, Button } from "tabler-react";

import headers from "../../utils/headers";
import SiteWrapper from "../SiteWrapper.js";
import { SelectField } from "../../components/FormikFields/SelectField";
import auth from "../../utils/auth";

import { Formik, Form, Field } from "formik";
import { TextInput } from "../../components/FormikFields/TextInput";
import { RichTextInput } from "../../components/FormikFields/RichTextInput";
import { DateInput } from "../../components/FormikFields/DateInput";

import { useHistory } from "react-router-dom";

function EditEffort() {
  let history = useHistory();
  const [initiatives, setInitiatives] = useState([]);

  //Find ID i URL
  const url = window.location.pathname.match(/^\/indsatser\/(\d+)/);
  //var id = url
  const effortid = url[1];

  const statuschoice = [
    { value: "Aktiv", label: "Aktiv" },
    { value: "Afventer", label: "Afventer" },
    { value: "Afsluttet", label: "Afsluttet" },
  ];

  const [effort, setEffort] = useState({
    title: "",
    description: "",
    start_date: "",
    responsible: "",
    end_date: "",
    initiative: "",
    status: "",
  });

  useEffect(() => {
    axios
      .get(
        `https://api.beecon.dk/initiatives?_where[strategy.business]=` +
          auth.get("userInfo").business.id,
        {
          headers: headers,
        }
      )
      .then((res) => {
        const data = res.data;

        setInitiatives(data);
      });
  });

  const addClick = () => {
    history.push("/indsatser/" + effortid + "/edit/kpi/tilfoej");
  };
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`https://api.beecon.dk/efforts/` + effortid, {
        headers: headers,
      })
      .then((res) => {
        const data = res.data;

        setEffort(data);
        setData(data);
      });
  }, [effortid]);

  const options = (
    <React.Fragment>
      <Button color="primary" type="submit">
        Gem
      </Button>
    </React.Fragment>
  );

  return (
    <SiteWrapper>
      <Formik
        enableReinitialize
        initialValues={{
          form: {
            title: effort.title,
            description: effort.description,
            start_date: effort.start_date,
            end_date: effort.end_date,
            responsible: effort.responsible,
            status: effort.status,
            initiative: effort.initiative.id,
          },
        }}
        onSubmit={(values) => {
          axios
            .put(`https://api.beecon.dk/efforts/` + effortid, values.form, {
              headers: headers,
            })
            .then((res) => {
              setTimeout(() => {
                history.push("/indsatser/");
              }, 1000);
            });
        }}
      >
        <Page.Content>
          <Form>
            <Page.Header options={options} />
            <TabbedCard initialTab="Generelt">
              <Tab title="Generelt">
                <Grid.Row cards={true}>
                  <Card>
                    <Card.Header>
                      <Card.Title>Rediger</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <p>Status</p>
                      <Field
                        name="form.status"
                        component={SelectField}
                        options={statuschoice}
                      />
                      <TextInput
                        name="form.title"
                        label="Titel på din indsats"
                      />
                      <RichTextInput
                        name="form.description"
                        onBlur={(e) => {
                          return;
                        }}
                        label="Beskrivelse af indsatsen"
                      />
                      <DateInput
                        name="form.start_date"
                        label="Start dato for din indsats"
                      />
                      <DateInput
                        name="form.end_date"
                        label="Slut dato for din indsats"
                      />
                      <TextInput name="form.responsible" label="Ansvarlige" />
                      <p>
                        <strong>
                          Hvilket initiativ tilhører denne indsats?
                        </strong>
                      </p>

                      <Field
                        name="form.initiative"
                        component={SelectField}
                        options={initiatives.map((initiative) => ({
                          value: initiative.id,
                          label: initiative.title,
                        }))}
                      />
                    </Card.Body>
                  </Card>
                </Grid.Row>
              </Tab>
              <Tab title="KPIs">
                <Button.List align="right">
                  <Button color="primary" onClick={addClick}>
                    Tilføj KPI
                  </Button>
                </Button.List>

                <Table>
                  <Table.Header>
                    <Table.ColHeader>KPI</Table.ColHeader>
                    <Table.ColHeader>Mål</Table.ColHeader>
                    <Table.ColHeader>Start værdi</Table.ColHeader>
                    <Table.ColHeader>Nuværende værdi</Table.ColHeader>
                    <Table.ColHeader>Vægt</Table.ColHeader>
                  </Table.Header>
                  <Table.Body>
                    {(data.KPIS || []).map((kpi) => (
                      <Table.Row>
                        <Table.Col>{kpi.title}</Table.Col>
                        <Table.Col>{kpi.goalvalue}</Table.Col>
                        <Table.Col>{kpi.startvalue}</Table.Col>
                        <Table.Col>{kpi.currentvalue}</Table.Col>
                        <Table.Col>{kpi.importance}</Table.Col>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Tab>
            </TabbedCard>
          </Form>
        </Page.Content>
      </Formik>
    </SiteWrapper>
  );
}

export default EditEffort;
